// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useMutation } from '@tanstack/react-query';
import { CustomAPIError } from 'core/errors/CustomAPIError';
import useImperativeRequest from 'hooks/useImperativeRequest';
import { MutationConfig } from 'lib/react-query';
import { useParams } from 'react-router-dom';

import { IMemberForm } from '../types';

export const createMember =
  ({
    partnershipId,
    makeRequest,
  }: {
    partnershipId?: string;
    makeRequest: any;
  }) =>
  async (values: IMemberForm): Promise<any> => {
    const { data, error } = await makeRequest({
      path: `api/v1/me/partners/${partnershipId}/users`,
      method: 'post',
      body: values,
    });

    if (error) {
      throw CustomAPIError.create(error.status, error?.response?.data);
    }

    return data;
  };

type UseCreateMemberOptions = {
  config?: MutationConfig<ReturnType<typeof createMember>>;
};

export const useCreateMember = ({ config }: UseCreateMemberOptions = {}) => {
  const { partnerId } = useParams();
  const [, makeRequest] = useImperativeRequest('lpVillage');

  return useMutation({
    ...config,
    mutationFn: createMember({ partnershipId: partnerId, makeRequest }),
  });
};
