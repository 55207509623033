// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { Grid, Stack, Text } from '@livingpackets/design-system-react-next';
import ContractStateInfoContainer from 'components/molecules/box/ContractStateInfo';
import useIntlDistanceTimestamp from 'hooks/useIntlDistanceTimestamp';
import { useTranslation } from 'react-i18next';

import IdentificationInformation from './IdentificationInformation';
import ProductInformation from './ProductInformation';

const RightColumn = ({ product, productClosingStateText }: any) => {
  const { t } = useTranslation('products');

  const intlDistanceTimestamp = useIntlDistanceTimestamp();

  return (
    <Grid container direction="column" spacing="1.75rem">
      <Grid item mobile={12}>
        <Stack gap=".75rem">
          <Text variant="titleXS" color="custom.neutral.black.50">
            {t('detail.contractState')}
          </Text>
          <Stack direction="row" alignItems="center" gap="0.75rem">
            <ContractStateInfoContainer contractState={product.contractState} />
            <Text variant="bodyTextS">
              {t('detail.lastContractStateChange', {
                lastContractStateChange: intlDistanceTimestamp(
                  product.contractStateUpdatedAt
                ),
              })}
            </Text>
          </Stack>
        </Stack>
      </Grid>
      <Grid item mobile={12}>
        <Grid container spacing={2}>
          {/* Product Information */}
          <Grid item mobile={12} laptop={4}>
            <ProductInformation
              product={product}
              productClosingStateText={productClosingStateText}
            />
          </Grid>
          {/* Identification Information */}
          <Grid item mobile={12} laptop={8}>
            <IdentificationInformation product={product} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RightColumn;
