// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { Alert, Text, useTheme } from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';
import { accountPartnershipInformation } from 'schemas/accountPartnershipInformation';

import { useAddresses } from '../../api/addresses/getAddresses';
import { usePartnership } from '../../api/partnership/getPartnership';
import { useAddressByType } from '../../hooks/useAddressByType';
import {
  PartnerBusinessStatusEnum,
  Partnership,
} from '../../types/partnership';

const isPartnerFormFilled = (partnership: Partnership) => {
  try {
    accountPartnershipInformation().validateSync(partnership);

    return true;
  } catch {
    return false;
  }
};

export const Alerts = () => {
  const { t } = useTranslation('account');
  const theme = useTheme();
  const { data: partnership } = usePartnership();
  const { data: addresses } = useAddresses();

  const mainAddress = useAddressByType({ addresses });

  if (!partnership) return null;

  // Sorry for the long name, but it's the best I could come up with ^^
  // Represent a partner that has the status pending but has not filled the mandatory fields
  // (in partnership form and has at least main address)
  const isPartnerMandatoryFieldsFilled =
    isPartnerFormFilled(partnership) && mainAddress;

  const isPartnerBlocked =
    partnership?.business_information.status ===
    PartnerBusinessStatusEnum.BLOCKED;

  return (
    <>
      {!isPartnerMandatoryFieldsFilled ? (
        <Alert
          title={t('partnership.pendingAccountTitle')}
          noIcon
          state="warning"
          sx={{
            marginTop: '1.625rem',
            backgroundColor: theme.palette.custom.secondary.yellow[10],
            color: theme.palette.custom.secondary.yellow[100],
          }}
        >
          <Text
            variant="bodyTextS"
            color={theme.palette.custom.secondary.yellow[100]}
          >
            {t('partnership.pendingAccountMessage')}
          </Text>
        </Alert>
      ) : null}

      {isPartnerBlocked && (
        <Alert
          title={t('partnership.blockedAccountTitle')}
          noIcon
          state="error"
          sx={{ marginTop: '1.625rem' }}
        >
          <Text variant="bodyTextS">
            {t('partnership.blockedAccountMessage')}
          </Text>
        </Alert>
      )}
    </>
  );
};
