// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { ReactNode } from 'react';

import {
  ArrowIcon,
  HelpIcon,
  Stack,
  Text,
  NewTooltip as Tooltip,
  useTheme,
} from '@livingpackets/design-system-react-next';

export const Card = ({
  dataTestId,
  hasBorder = false,
  icon: Icon,
  title,
  tooltip,
  color,
  value,
  label,
  onClick,
}: {
  dataTestId?: string;
  hasBorder?: boolean;
  icon: any;
  title: string;
  tooltip?: string | ReactNode;
  color?: string;
  value: number | string;
  label: any;
  onClick?: () => void;
}) => {
  const theme = useTheme();

  const clickable = !!onClick;

  const clickableSX = {
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)',
    },
    '&:active': {
      boxShadow:
        '0px 4px 16px 0px rgba(0, 0, 0, 0.08), 0px 0px 3px 0px rgba(0, 0, 0, 0.3) inset',
    },
  };

  return (
    <Stack
      data-testid={dataTestId}
      direction="column"
      padding="1.5rem"
      spacing="1rem"
      onClick={onClick}
      sx={{
        backgroundColor: 'custom.neutral.white.pure',
        border: hasBorder ? '1px solid rgba(204, 204, 204, 1)' : 'none',
        transition: 'box-shadow 0.1s ease-in-out',
        borderRadius: '0.75rem',
        ...(clickable ? clickableSX : {}),
      }}
    >
      <Stack
        direction="row"
        gap="0.5rem"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack
          direction="row"
          gap="0.5rem"
          justifyContent="space-between"
          alignItems="center"
        >
          <Icon color={color} />
          <Text variant="titleM">{title}</Text>
          {tooltip && (
            <Tooltip enterDelay={250} placement="top" title={tooltip}>
              <HelpIcon
                size="1rem"
                color={theme.palette.custom.neutral.black[50]}
              />
            </Tooltip>
          )}
        </Stack>
        {clickable && <ArrowIcon color={color} />}
      </Stack>
      <Stack direction="row" gap="0.5rem" alignItems="baseline">
        <Text variant="titleXL" data-testid={`${dataTestId}-value`}>
          {value}
        </Text>
        <Text
          variant="bodyTextS"
          data-testid={`${dataTestId}-label`}
          color="custom.neutral.black.30"
        >
          {label}
        </Text>
      </Stack>
    </Stack>
  );
};
