// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  Paper,
  PartnershipIcon,
} from '@livingpackets/design-system-react-next';
import useIntlFormatTimestamp from 'hooks/useIntlFormatTimestamp';
import { useTranslation } from 'react-i18next';

import IdentificationForm from './IdentificationForm';
import { usePartnership } from '../../../api/partnership/getPartnership';
import { LastUpdateAt } from '../../commons/LastUpdateAt';
import { Loading } from '../../commons/Loading';

export const Information = () => {
  const { t } = useTranslation('account');
  const format = useIntlFormatTimestamp();

  const { isPending, error, data: partnership } = usePartnership();

  if (isPending && !partnership) return <Loading />;

  // Global error screen handled by parent component
  if (error) return <></>;

  return (
    <>
      <Paper
        headerIcon={PartnershipIcon}
        headerTitle={t('partnership.information.title')}
        headerInfo={<LastUpdateAt date={format(partnership.updated_at)} />}
        sx={{ p: 0, pt: '1.5rem', gap: '1rem' }}
      >
        <IdentificationForm partnership={partnership} />
      </Paper>
    </>
  );
};
