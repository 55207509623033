// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import * as Sentry from '@sentry/react';

export const SentryCaptureException = (error: any, appError?: any) => {
  if (error) {
    Sentry.captureException(error);
  }
  if (appError) {
    Sentry.captureException(appError);
  }
};
