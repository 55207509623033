// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import useImperativeRequest from 'hooks/useImperativeRequest';
import useToastMessages from 'hooks/useToastMessages';
import { useTranslation } from 'react-i18next';

export type RemoteControlCommand = 'COMMAND_TYPE_RECEIVED';

export const REMOTE_COMMAND_STATUS_WAITING = [
  'REMOTE_COMMAND_STATUS_QUEUED',
  'REMOTE_COMMAND_STATUS_IN_PROGRESS',
];

export const REMOTE_COMMAND_STATUS_ERROR = [
  'REMOTE_COMMAND_STATUS_UNSPECIFIED',
  'REMOTE_COMMAND_STATUS_TIMEOUT',
  'REMOTE_COMMAND_STATUS_REJECTED',
  'REMOTE_COMMAND_STATUS_REMOVED',
  'REMOTE_COMMAND_STATUS_CANCELED',
  'REMOTE_COMMAND_STATUS_FAILED',
];

export const REMOTE_COMMAND_STATUS = 'REMOTE_COMMAND_STATUS_SUCCEEDED';

const useRemoteControl = () => {
  const [, makeRequest] = useImperativeRequest('deviceV3');
  const { error: toastError } = useToastMessages();
  const { t } = useTranslation('remoteControl');

  const getProductConnectivity = async (
    partnerId: string,
    productId: string
  ) => {
    const { error, data } = await makeRequest({
      path: `me/partners/${partnerId}/products/${productId}/connectivity/lifecycle`,
    });

    if (error) {
      if (error.response.status === 403) {
        toastError(t('errors.productConnetion'));
      }
    }

    if (data) {
      return data;
    }
  };

  const getRemoteCommand = async (
    lpUi: string,
    partnerId: string,
    remoteCommandId: string
  ) => {
    const { error, data } = await makeRequest({
      path: `me/partners/${partnerId}/products/${lpUi}/remote-commands/${remoteCommandId}`,
      method: 'get',
    });

    if (error) {
      toastError(t('errors.getRemoteCommand'));
    }

    if (data) {
      return data;
    }

    return { success: false };
  };

  const sendRemoteCommand = async (
    partnerId: any,
    shipmentId: any,
    lpui: any,
    action: RemoteControlCommand
  ) => {
    const { error, data } = await makeRequest({
      path: `me/partners/${partnerId}/remote-commands`,
      method: 'post',
      body: {
        lp_ui: lpui,
        type: action,
        shipment_id: shipmentId,
      },
    });

    if (error) {
      toastError(t('errors.sendRemoteCommand'));
    }

    if (data) {
      return data;
    }

    return { success: false };
  };

  return { getProductConnectivity, sendRemoteCommand, getRemoteCommand };
};

export default useRemoteControl;
