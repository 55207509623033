// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

type APIError = {
  code: number | string; // number: v3, string: v1/v2
  message: string;
  details: any;
};

// Custom error class for API errors
export class CustomAPIError extends Error {
  apiError: APIError;
  statusCode: number;

  // Factory method to create specific error instances based on status code
  static create(statusCode: number, apiError: APIError) {
    const ErrorClass = errorMap[statusCode] || CustomAPIError;

    return new ErrorClass(statusCode, apiError);
  }

  constructor(statusCode: number, apiError: APIError) {
    super(apiError.message);
    this.statusCode = statusCode;
    this.apiError = apiError;
  }
}

export class BadRequest400Error extends CustomAPIError {}
export class Unauthorized401Error extends CustomAPIError {}
export class Forbidden403Error extends CustomAPIError {}
export class NotFound404Error extends CustomAPIError {}
export class InternalServerError500Error extends CustomAPIError {}

const errorMap: { [key: number]: typeof CustomAPIError } = {
  400: BadRequest400Error,
  401: Unauthorized401Error,
  403: Forbidden403Error,
  404: NotFound404Error,
  500: InternalServerError500Error,
};
