// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  NewTooltip as Tooltip,
  Text,
  styled,
  ShipmentOnReturnIcon,
  ButtonV2 as Button,
} from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';

const ControlIconWrap = styled(ShipmentOnReturnIcon)`
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.palette.custom.primary[100]};
  }
`;

export const TooltipReturnProduct = ({
  onClick,
  iconOnly = false,
  fullWidth = false,
}: {
  onClick?: any;
  iconOnly?: boolean;
  fullWidth?: boolean;
}) => {
  const { t } = useTranslation('shipments');

  return (
    <Tooltip
      id="remote-control-tooltip"
      placement="bottom"
      enterDelay={200}
      title={
        <>
          <Text variant="titleM" marginTop="0.5rem">
            {t('deployReturnInfo.title')}
          </Text>
          <Text variant="bodyTextS" marginTop="0.5rem">
            {t('deployReturnInfo.description')}
          </Text>
        </>
      }
      sx={{ maxWidth: '16.0625rem' }}
      onClick={onClick}
    >
      {iconOnly ? (
        <ControlIconWrap data-testid="return-product-tooltip-icon" />
      ) : (
        <Button
          variant="primary"
          data-testid="return-product-tooltip-button"
          icon={ControlIconWrap}
          fullWidth={fullWidth}
          textVariant="titleM"
        >
          {t('deployReturnInfo.title')}
        </Button>
      )}
    </Tooltip>
  );
};
