// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  Box,
  Grid,
  NoneIcon,
  Stack,
  Text,
} from '@livingpackets/design-system-react-next';
import useIntlFormatTimestamp from 'hooks/useIntlFormatTimestamp';
import { useTranslation } from 'react-i18next';
import { formatLpui } from 'utils/product';

import GreyCard from './GreyCard';
import { ProductEntity } from '../../entities/ProductEntity';

type Props = {
  product: ProductEntity;
};

const IdentificationInformation = ({ product }: Props) => {
  const { t } = useTranslation('products');

  const formatTimestamp = useIntlFormatTimestamp();

  return (
    <Stack gap=".75rem">
      <Text variant="titleXS" color="custom.neutral.black.50">
        {t('detail.identificationInformation.title')}
      </Text>
      <GreyCard>
        <Grid container spacing="1.5rem">
          {/* Left Column */}
          <Grid item mobile={12} tablet={6}>
            <Stack gap="1.375rem" justifyContent="space-between">
              <Stack gap=".25rem">
                <Text variant="titleXS" color="custom.neutral.black.50">
                  {t('detail.identificationInformation.holder')}
                </Text>
                <Text variant="bodyTextS">
                  {product.holderName || (
                    <Box height="1rem">
                      <NoneIcon size=".9375rem" />
                    </Box>
                  )}
                </Text>
              </Stack>
              <Stack gap=".25rem">
                <Text variant="titleXS" color="custom.neutral.black.50">
                  {t('detail.identificationInformation.partnershipAssignment')}
                </Text>
                <Text variant="bodyTextS">
                  {product.partnerSince ? (
                    t(
                      'detail.identificationInformation.partnershipAssignmentInfo',
                      {
                        date: formatTimestamp(product.partnerSince),
                      }
                    )
                  ) : (
                    <Box height="1rem">
                      <NoneIcon size=".9375rem" />
                    </Box>
                  )}
                </Text>
              </Stack>
            </Stack>
          </Grid>
          {/* Right Column */}
          <Grid item mobile={12} tablet={6}>
            <Stack gap="1.375rem">
              <Stack gap=".25rem">
                <Text variant="titleXS" color="custom.neutral.black.50">
                  {t('detail.identificationInformation.productSerialNumber')}
                </Text>
                <Text variant="bodyTextS">{formatLpui(product.lpUi)}</Text>
              </Stack>
              <Stack gap=".25rem">
                <Text variant="titleXS" color="custom.neutral.black.50">
                  {t('detail.identificationInformation.shipmentsOperated')}
                </Text>
                <Text variant="bodyTextS">
                  {product.shipmentsOperated || (
                    <Box height="1rem">
                      <NoneIcon size=".9375rem" />
                    </Box>
                  )}
                </Text>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </GreyCard>
    </Stack>
  );
};

export default IdentificationInformation;
