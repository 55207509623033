// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useState } from 'react';

import {
  AddIcon,
  ButtonV2 as Button,
  EmptyState,
  Grid,
  Paper,
  RefreshIcon,
  Stack,
  Text,
} from '@livingpackets/design-system-react-next';
import { normalizeKey } from 'helpers/i18n';
import { useTranslation } from 'react-i18next';
import { disabledContent } from 'utils/disabledElementCss';

import { Card } from './Card';
import { AddressForm } from './Form';
import { useCreateAddress } from '../../../api/addresses/createAddress';
import { useAddresses } from '../../../api/addresses/getAddresses';
import { usePartnership } from '../../../api/partnership/getPartnership';
import { useAddressByType } from '../../../hooks/useAddressByType';
import { AddressTypesKeys, INITIAL_ADDRESS } from '../../../types/address';
import { PartnerBusinessStatusEnum } from '../../../types/partnership';
import { ErrorEmptyState } from '../ErrorEmptyState';
import { Loading } from '../Loading';

/**
 * This component is the base for Finance and Supply tabs
 * as they where pretty much identical when first implemented.
 */
const AddressManagement = ({
  addressType,
  headerIcon,
}: {
  addressType: AddressTypesKeys;
  headerIcon: React.ElementType;
}) => {
  const {
    data: addresses,
    isPending: loadingAddresses,
    error,
    refetch,
    isLoading,
  } = useAddresses();
  const { t } = useTranslation('account');
  const { mutate, isPending } = useCreateAddress();

  const { data: partnership } = usePartnership();

  const isPartnerBlocked =
    partnership?.business_information?.status ===
    PartnerBusinessStatusEnum.BLOCKED;

  const [editionMode, setEditionMode] = useState(false);
  const [showAddAddress, setShowAddAddress] = useState(false);

  const mainAddress = useAddressByType({ addresses });
  const localTypeAddress = useAddressByType({
    addresses,
    type: addressType,
    exclusive: true,
  });

  const renderContent = () => {
    if (loadingAddresses && !addresses) return <Loading height="21.875rem" />;

    if (error) return <ErrorEmptyState />;

    // `addressType` has it own address
    if (localTypeAddress)
      return (
        <Card
          canEdit
          showIdentificationName
          address={localTypeAddress}
          editionMode={editionMode}
          setEditionMode={setEditionMode}
        />
      );

    // Main address with `addressType` address
    if (mainAddress?.addressTypes?.includes(addressType))
      return (
        <Card
          address={mainAddress}
          editionMode={editionMode}
          setEditionMode={setEditionMode}
        />
      );

    // In any other case, we show the empty state
    return (
      <EmptyState type="Addresses" sx={{ mb: '1rem' }}>
        <>
          <Text variant="titleM" color="custom.neutral.black.50">
            {t('address.emptyState.title')}
          </Text>
          <Text variant="bodyTextL" color="custom.neutral.black.50">
            {t('address.emptyState.subtitle')}
          </Text>
        </>
      </EmptyState>
    );
  };

  const renderAddAddress = () => {
    // Do not show the add address button if we are editing the current address
    if (editionMode) return;

    // Show the `add new address` form
    if (showAddAddress) {
      return (
        <Grid item>
          <AddressForm
            alwaysShowActions
            showIdentificationName
            initialValues={{
              ...INITIAL_ADDRESS,
              // Overwrite the address type with the one from props
              addressTypes: [addressType],
              company: '',
            }}
            disabled={isPartnerBlocked}
            mutate={mutate}
            isPending={isPending}
            onSave={() => setShowAddAddress(false)}
            onDismiss={() => setShowAddAddress(false)}
          />
        </Grid>
      );
    }

    // No button to display if we already have an local type address
    if (localTypeAddress || isLoading || error) return;

    // Show the add button with the correct wording depending on main address or not
    let label = t(
      normalizeKey(`partnership.${addressType.toLowerCase()}.addAddress`)
    );

    if (mainAddress) {
      label = t(
        normalizeKey(
          `partnership.${addressType.toLowerCase()}.useDifferentAddress`
        )
      );
    }

    return (
      <Grid item>
        <Button
          data-testid="add-address-button"
          fullWidth
          disabled={isPartnerBlocked}
          variant="secondary"
          onClick={() => setShowAddAddress(true)}
        >
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            gap="0.625rem"
          >
            <span>{label}</span>
            <AddIcon />
          </Stack>
        </Button>
      </Grid>
    );
  };

  return (
    <>
      <Paper
        headerIcon={headerIcon}
        headerTitle={t(
          normalizeKey(`partnership.${addressType.toLowerCase()}.title`)
        )}
        headerSubtitle={t(
          normalizeKey(`partnership.${addressType.toLowerCase()}.subtitle`)
        )}
        headerInfo={
          error && (
            <Button
              variant="tertiary"
              size="small"
              icon={RefreshIcon}
              onClick={refetch as any}
              data-testid="refresh-addresses-button"
            />
          )
        }
        sx={{ p: 0, pt: '1.5rem', gap: '1.5rem', minHeight: '33.75rem' }}
        LeftHeaderProps={{
          sx: {
            opacity: error ? 0.3 : 1,
          },
        }}
      >
        <Grid
          container
          direction="column"
          rowSpacing="2rem"
          sx={{
            ...(isPartnerBlocked && disabledContent),
          }}
        >
          {!showAddAddress && <Grid item>{renderContent()}</Grid>}
          {renderAddAddress()}
        </Grid>
      </Paper>
    </>
  );
};

export default AddressManagement;
