// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useQuery } from '@tanstack/react-query';
import { CustomAPIError } from 'core/errors/CustomAPIError';
import useImperativeRequest from 'hooks/useImperativeRequest';
import { ExtractFnReturnType, QueryConfig } from 'lib/react-query';
import { useParams } from 'react-router-dom';

import { ProductEntity } from '../entities/ProductEntity';

export const getProduct = async ({
  partnerId,
  productId,
  makeRequest,
}: {
  partnerId: string;
  productId: string;
  makeRequest: any;
}): Promise<ProductEntity> => {
  const { data, error } = await makeRequest({
    path: `me/partners/${partnerId}/products/${productId}/state`,
  });

  // To be moved in makeRequest function later
  if (error) {
    throw CustomAPIError.create(error.status, error?.response?.data);
  }

  return ProductEntity.create(data);
};

type QueryFnType = typeof getProduct;

type UseProductOptions = {
  partnerId?: string;
  productId?: string;
  config?: QueryConfig<QueryFnType>;
};

export const useProduct = ({
  partnerId,
  productId,
  config,
}: UseProductOptions = {}) => {
  const { partnerId: paramsPartnerId, lpUi: paramsProductId } = useParams<{
    lpUi: string;
    partnerId: string;
  }>();

  const [, makeRequest] = useImperativeRequest('deviceV3');

  const partner = partnerId || paramsPartnerId!;
  const product = productId || paramsProductId!;

  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['product', productId || paramsProductId!],
    queryFn: () =>
      getProduct({
        partnerId: partner,
        productId: product,
        makeRequest,
      }),
  });
};
