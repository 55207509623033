// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import {
  articleCodeBoxList,
  articleCodeBoxProList,
  articleCodeTabletList,
  ProductArticleCodeEnum,
  ProductTypeEnum,
} from 'features/products';

export const getProductType = (articleCode?: string) => {
  if (!articleCode) {
    return ProductTypeEnum.UNKNOWN;
  }

  if (articleCodeBoxList.includes(articleCode as ProductArticleCodeEnum)) {
    return ProductTypeEnum.BOX;
  }

  if (articleCodeBoxProList.includes(articleCode as ProductArticleCodeEnum)) {
    return ProductTypeEnum.BOX_PRO;
  }

  if (articleCodeTabletList.includes(articleCode as ProductArticleCodeEnum)) {
    return ProductTypeEnum.TABLET;
  }

  return ProductTypeEnum.UNKNOWN;
};

/**
 * Formats the given LPUI (Local Product Unique Identifier) string.
 *
 * This function performs the following transformations:
 * 1. Removes the first two characters of the string.
 * 2. Removes all whitespace characters.
 * 3. Inserts a hyphen ('-') every three characters.
 * 4. Removes the leading hyphen if present.
 *
 * @param lpui - The LPUI string (format: `sn0123...`) to format. If not provided, an empty string is returned.
 * @returns The LPUI string in the format : `012-3xx-...`.
 */
export const formatLpui = (lpui?: string) =>
  lpui
    ?.slice(2)
    .replace(/\s+/g, '')
    .replace(/(.{3})/g, '-$1')
    .slice(1) || '';

/**
 * Converts any format of LPUI to the format `sn0123...`.
 *
 * This function performs the following transformations:
 * 1. Removes all hyphens ('-') and whitespace characters.
 * 2. Ensures the string starts with 'sn'.
 *
 * @param lpui - The LPUI string in any format (`sn0123...` or `0123....` or `012-3..-...`).
 * @returns The LPUI string in the format `sn0123...`.
 */
export const normalizeLpui = (
  lpui?: string,
  { prefix = 'sn' }: { prefix?: 'sn' | 'vb' } = {}
) => {
  if (!lpui) {
    return '';
  }

  // Remove all hyphens and whitespace characters
  const cleanedLpui = lpui.replace(/[-\s]/g, '');

  // Ensure the string starts with the specified prefix
  return cleanedLpui.startsWith(prefix)
    ? cleanedLpui
    : `${prefix}${cleanedLpui}`;
};

/**
 * Custom error class for incorrect LPUI format.
 */
export class IncorrectLPUIFormatError extends Error {}

/**
 * Validates the given LPUI string.
 *
 * This function performs the following checks:
 * 1. Ensures the LPUI string is not empty.
 * 2. Normalizes the LPUI string and checks if it matches the expected format.
 * 3. Formats the LPUI string and checks if it matches the expected format.
 *
 * @param lpui - The LPUI string to validate.
 * @returns `true` if the LPUI string is valid, otherwise throws an IncorrectLPUIFormatError.
 * @throws IncorrectLPUIFormatError if the LPUI string is empty or does not match the expected format.
 */
export const validateLpui = (lpui: string) => {
  if (!lpui) {
    throw new IncorrectLPUIFormatError();
  }

  const normalizedLpui = normalizeLpui(lpui);

  if (/^(sn|vb)[a-zA-Z0-9]{18}$/.test(normalizedLpui)) {
    return true;
  }

  const formattedLpui = formatLpui(lpui);
  if (/^([a-zA-Z0-9]{3}-){5}[a-zA-Z0-9]{3}$/.test(formattedLpui)) {
    return true;
  }

  throw new IncorrectLPUIFormatError();
};
