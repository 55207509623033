// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

export enum ProductTypeEnum {
  BOX = 'box',
  BOX_PRO = 'boxPro',
  TABLET = 'tablet',
  UNKNOWN = 'unknown',
}

export enum ProductClosingStateEnum {
  CONFIG_32L = 'CONFIG_32L',
  CONFIG_2L = 'CONFIG_2L',
  CONFIG_OPEN = 'CONFIG_OPEN',
  CONFIG_CLOSED_FOLD = 'CONFIG_CLOSED_FOLD',
}

export enum ProductArticleCodeEnum {
  TABLET_V_2 = 'A0001',
  THE_BOX_V2 = 'A00000171',
  THE_BOX_V2_PROTO = 'A00000226',
  FRONT_FACE_V_2_1 = 'A_00000176',
  FRONT_FACE_V_2_2 = 'A00000176',
  THE_BOX_PRO_V_1_2 = 'A00000172',
  THE_BOX_PRO_S_V = 'A00000282',
  THE_BOX_PRO_S_R = 'A00000283',
  THE_BOX_PRO_M = 'A00000252',
  THE_BOX_PRO_L_V = 'A00000287',
  THE_BOX_PRO_L_R = 'A00000288',
  TABLET_V_3_1 = 'A_0005',
  TABLET_V_3_2 = 'A0005',
  THE_BUMPER_BOX_V_0_1 = 'A_0006',
  THE_BUMPER_BOX_V_0_2 = 'A0006',
  THE_TABLET_HOLDER_V_1_2 = 'A00000173',
  THE_TABLET_HOLDER_PANEL_MOUNTED = 'A00000273',
  MULTI_SENSOR_ADD_ON_V_0_1 = 'A_0009',
  MULTI_SENSOR_ADD_ON_V_0_2 = 'A0009',
  BOX_ADAPTER_V_1_1 = 'A_0010',
  BOX_ADAPTER_V_1_2 = 'A0010',
}

export const articleCodeBoxList = [
  ProductArticleCodeEnum.THE_BOX_V2,
  ProductArticleCodeEnum.THE_BOX_V2_PROTO,
];

export const articleCodeBoxProList = [
  ProductArticleCodeEnum.THE_BOX_PRO_V_1_2,
  ProductArticleCodeEnum.THE_BOX_PRO_S_V,
  ProductArticleCodeEnum.THE_BOX_PRO_S_R,
  ProductArticleCodeEnum.THE_BOX_PRO_M,
  ProductArticleCodeEnum.THE_BOX_PRO_L_V,
  ProductArticleCodeEnum.THE_BOX_PRO_L_R,
];

export const articleCodeTabletList = [
  ProductArticleCodeEnum.THE_TABLET_HOLDER_V_1_2,
  ProductArticleCodeEnum.THE_TABLET_HOLDER_PANEL_MOUNTED,
  ProductArticleCodeEnum.FRONT_FACE_V_2_1,
  ProductArticleCodeEnum.FRONT_FACE_V_2_2,
];
