// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useCallback } from 'react';

import useImperativeRequest from 'hooks/useImperativeRequest';
import {
  IMember,
  IMemberForm,
  IMemberPost,
  INITIAL_STATE_MEMBER,
} from 'models/user';
import { useTranslation } from 'react-i18next';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';
import { create } from 'zustand';

import useToastMessages from './useToastMessages';

interface IPartnerMemberStore {
  partnerMember: IMember;
  setPartnerMember: (partnerMember: IMember) => void;
}

export const usePartnerMemberStore = create<IPartnerMemberStore>(set => ({
  partnerMember: INITIAL_STATE_MEMBER,
  setPartnerMember: (partnerMember: IMember) => set({ partnerMember }),
}));

const usePartnerMemberManagement = () => {
  const [{ loading }, makeRequest] = useImperativeRequest('lpVillage');
  const { error: toastError, success: toastSuccess } = useToastMessages();
  const activePartner = useMyPartnersStore(activePartnerSelector);
  const { t } = useTranslation('userManagement');

  const getPartnerMember = useCallback(
    async (memberId: string) => {
      const { error, data } = await makeRequest({
        path: `api/v1/me/partners/${activePartner.id}/users/${memberId}`,
        method: 'get',
      });
      if (error) {
        toastError('messages:getPartnerMemberError');

        return { success: false, member: null };
      }

      return { success: true, partnerMember: data };
    },
    [makeRequest, toastError, activePartner]
  );

  const addMember = useCallback(
    async (member: IMemberPost) => {
      const { error, data } = await makeRequest({
        path: `api/v1/me/partners/${activePartner.id}/users`,
        method: 'post',
        body: { ...member },
      });

      if (!error) {
        toastSuccess('messages:partnerMemberCreateSuccess.message');

        return { success: true, data };
      }
      let errorMessage;

      if (
        error.response.data.code === 'lpvillageAlreadyAccessPartnerForAccount'
      ) {
        errorMessage = t('create.errorMessages.alreadyAccess');
      } else if (
        error.response.data.code === 'lpvillageMembershipInvitationImpossible'
      ) {
        errorMessage = t('create.errorMessages.personnalAccountType');
      } else {
        errorMessage = t('create.errorMessages.generic');
      }

      toastError(errorMessage);

      return { success: false };
    },
    [makeRequest, activePartner.id, toastSuccess, toastError, t]
  );

  const editMember = useCallback(
    async (member: IMemberPost, memberId: string) => {
      const { error, data } = await makeRequest({
        path: `api/v1/me/partners/${activePartner.id}/users/${memberId}`,
        method: 'put',
        body: { ...member },
      });
      if (error) {
        toastError('messages:partnerMemberEditError.message', { wide: true });

        return { success: false };
      }
      toastSuccess('messages:partnerMemberEditSuccess.message', { wide: true });

      return { success: true, data };
    },
    [makeRequest, toastSuccess, toastError, activePartner.id]
  );

  const deleteMember = useCallback(
    async (memberId: string) => {
      const { data, error } = await makeRequest({
        path: `api/v1/me/partners/${activePartner.id}/users/${memberId}`,
        method: 'delete',
      });

      if (error) {
        toastError('messages:partnerMemberDeleteError.message');

        return { success: false };
      }
      toastSuccess('messages:partnerMemberDeleteSuccess.message');

      return { success: true, data };
    },
    [activePartner.id, makeRequest, toastError, toastSuccess]
  );

  const fetchMemberRoles = useCallback(async () => {
    const { error, data } = await makeRequest({
      path: 'api/v1/me/roles',
      method: 'get',
    });

    if (error) {
      toastError('messages:partnerRoleError');

      return { success: false };
    }

    return { success: true, data };
  }, [makeRequest, toastError]);

  const searchMember = useCallback(
    async (memberName: string) => {
      const { error, data } = await makeRequest({
        path: `api/v1/me/partners/${activePartner.id}/members?offset=0&order=DESC&pageSize=5&sortBy=firstName&search=${memberName}`,
        method: 'get',
      });

      if (error) {
        toastError('messages:getSearchMemberError');

        return { success: false, member: null };
      }

      return { success: true, partnerMemberList: data.items };
    },
    [makeRequest, toastError, activePartner]
  );

  const getMemberPostFromMemberFormData = useCallback(
    (memberFormData: IMemberForm): IMemberPost => ({
      firstName: memberFormData.firstName,
      lastName: memberFormData.lastName,
      email: memberFormData.email,
      roleId: memberFormData.roleId,
    }),
    []
  );

  return {
    loading,
    addMember,
    getPartnerMember,
    editMember,
    deleteMember,
    fetchMemberRoles,
    searchMember,
    getMemberPostFromMemberFormData,
  };
};

export default usePartnerMemberManagement;
