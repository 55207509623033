// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { Backdrop, Box } from '@livingpackets/design-system-react-next';
import whiteDotsLoader from 'assets/lotties/white-dots-loader.json';
import Lottie from 'react-lottie';

export const LoadingModal = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (value: boolean) => void;
}) => (
  <Backdrop
    open={open}
    onClick={() => setOpen(false)}
    data-testid="delete-account-loading-modal-backdrop"
  >
    <Box
      sx={{
        width: '8rem',
        height: '8rem',
        color: 'white',
      }}
    >
      <Lottie
        isClickToPauseDisabled
        options={{
          loop: true,
          autoplay: true,
          animationData: whiteDotsLoader,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
      />
    </Box>
  </Backdrop>
);
