// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useEffect, useState } from 'react';

import {
  Box,
  ButtonV2 as Button,
  Stack,
  Text,
} from '@livingpackets/design-system-react-next';
import { PATHS } from 'configs';
import { STATE_ORIGIN_PRODUCT } from 'features/deploy-return';
import { ProductEntity } from 'features/products';
import {
  TooltipRemoteControl,
  TooltipReturnProduct,
} from 'features/remoteControl';
import useIntlFormatTimestamp from 'hooks/useIntlFormatTimestamp';
import useShipmentDetail from 'hooks/useShipmentDetail';
import { IShipment, ShipmentContractStateEnum } from 'models/shipment';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';

type Props = {
  product: ProductEntity;
  imgSrc?: {
    src: string;
    src2x: string;
    src3x: string;
  };
  setIsOpen(value: boolean): void;
  productScreenImageBase64?: string;
};

const LeftColumn = ({
  product,
  imgSrc,
  setIsOpen,
  productScreenImageBase64,
}: Props) => {
  const { t } = useTranslation('products');
  const navigate = useNavigate();

  const activePartner = useMyPartnersStore(activePartnerSelector);

  const formatTimestamp = useIntlFormatTimestamp();

  // Product screen modal state
  const [shipment, setShipment] = useState<IShipment>();

  // TODO: Voir pour faire un react-query getShipment (ce useShipmentDetail est trop dense)
  const { getShipmentDetail } = useShipmentDetail();

  // Fecth any associated shipment details
  useEffect(() => {
    if (!product?.lastShipmentId) {
      return;
    }
    getShipmentDetail(activePartner.id, product?.lastShipmentId).then(
      ({ success, shipment }) => {
        if (success) {
          setShipment(shipment);
        }
      }
    );
  }, [product?.lastShipmentId, activePartner.id, getShipmentDetail]);

  const isProductDeployed =
    product.contractState === ShipmentContractStateEnum.DEPLOYED;
  const isRelatedShipmentArrived =
    shipment?.contract_state === ShipmentContractStateEnum.ARRIVED;
  const isRelatedReturnShipmentTransferred =
    shipment?.return_shipment_id &&
    shipment.return_shipment?.contract_state ===
      ShipmentContractStateEnum.TRANSFERRED;

  return (
    <Stack gap={0.5}>
      <Text variant="titleL">{t(product.productTitle)}</Text>
      <Stack direction="row" alignItems="center" mt="0.5rem">
        <Text variant="titleXS" color="custom.neutral.black.100">
          {t('detail.lastUpdate')}
        </Text>
        <Text variant="bodyTextXS">
          &nbsp;
          {formatTimestamp(product.updatedAt, {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
          })}
        </Text>
      </Stack>
      <Box maxWidth="16.625rem" alignSelf="center">
        <img
          src={imgSrc?.src}
          alt="MDN"
          srcSet={`${imgSrc?.src2x} 2x ${imgSrc?.src3x} 3x`}
        />
      </Box>
      <Stack gap="1rem">
        <Button
          fullWidth
          data-testid="display-screen-button"
          variant="secondary"
          disabled={!productScreenImageBase64}
          onClick={() => setIsOpen(true)}
        >
          {t('detail.displayScreen')}
        </Button>
        {isProductDeployed && activePartner.remoteControlEnabled && (
          <TooltipRemoteControl fullWidth shipmentId={product.lastShipmentId} />
        )}
        {isRelatedShipmentArrived && isRelatedReturnShipmentTransferred && (
          <TooltipReturnProduct
            fullWidth
            onClick={() =>
              navigate(
                generatePath(PATHS.SHIPMENT.DEPLOY_RETURN, {
                  partnerId: product.partnerId,
                  shipmentId: product.lastShipmentId,
                }),
                {
                  state: {
                    origin: STATE_ORIGIN_PRODUCT,
                    productId: product.productId,
                  },
                }
              )
            }
          />
        )}
      </Stack>
    </Stack>
  );
};

export default LeftColumn;
