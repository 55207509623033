// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useMemo, useState } from 'react';

import { NoneIcon } from '@livingpackets/design-system-react-next';
import { useQuery } from '@tanstack/react-query';
import useToastMessages from 'hooks/useToastMessages';
import { useTranslation } from 'react-i18next';
import useAppState, { tokenSelector } from 'stores/appState/useAppState';

import { ProductEntity } from '../entities/ProductEntity';
import { ProductClosingStateEnum, ProductTypeEnum } from '../types';

const useProductDetails = (product?: ProductEntity) => {
  const { t } = useTranslation('products');

  const appToken = useAppState(tokenSelector);

  const { error: toastError } = useToastMessages();

  // Blob of the Product Screen Image
  const [productScreenImageBase64, setProductScreenImageBase64] =
    useState<string>();

  // Fetch the Product Screen Image
  useQuery({
    refetchOnWindowFocus: false,
    enabled: !!product?.currentScreen,
    retry: false,
    queryKey: ['product-screen', product?.currentScreen],
    queryFn: async () => {
      if (!product?.currentScreen) {
        return;
      }

      const response = await fetch(product?.currentScreen, {
        method: 'GET',
        headers: { Authorization: `Bearer ${appToken}` },
      });

      if (!response.ok) {
        toastError(t('detail.errorLabel'));

        return;
      }

      const blob = await response.blob();
      let reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        if (typeof reader.result === 'string') {
          setProductScreenImageBase64(reader.result);
        }
      };
    },
  });

  const productClosingStateText = useMemo(() => {
    if (
      product?.productType === ProductTypeEnum.BOX ||
      product?.productType === ProductTypeEnum.BOX_PRO
    ) {
      switch (product?.closingState) {
        case ProductClosingStateEnum.CONFIG_OPEN:
          return t('common.closingState.open');
        case ProductClosingStateEnum.CONFIG_32L:
          return t('common.closingState.32L');
        case ProductClosingStateEnum.CONFIG_2L:
          return t('common.closingState.2L');
        case ProductClosingStateEnum.CONFIG_CLOSED_FOLD:
          return t('common.closingState.closedFold');
      }
    }

    return <NoneIcon size=".9375rem" />;
  }, [product?.productType, product?.closingState, t]);

  return {
    imgSrc: product?.showcaseImgSources,
    productScreenImageBase64,
    productClosingStateText,
  };
};

export default useProductDetails;
