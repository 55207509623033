// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  ButtonV2 as Button,
  NewTooltip as Tooltip,
  Text,
  ControlIcon,
  styled,
  Stack,
} from '@livingpackets/design-system-react-next';
import { PATHS } from 'configs';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

const ControlIconWrap = styled(ControlIcon)`
  cursor: pointer;
  display: flex;
  justify-content: center;
  &:hover {
    color: ${({ theme }) => theme.palette.custom.primary[100]};
  }
  width: 24px;
`;
const pdfLink: any = {
  FR: 'https://drive.google.com/file/d/1Xj5o6kxr2NnXKQ4mclDtpZpzX6Wxk43K/edit',
  EN: 'https://drive.google.com/file/d/1Xj5o6kxr2NnXKQ4mclDtpZpzX6Wxk43K/edit',
  DE: 'https://drive.google.com/file/d/1Xj5o6kxr2NnXKQ4mclDtpZpzX6Wxk43K/edit',
};
// 'https://docs.google.com/presentation/d/1tBD_Qf_Zar_1B2EamLm-jWqjtcxxN-_RvvNPgh3p6-I/edit#slide=id.g2b505e402b3_1_9';
export const TooltipRemoteControl = ({
  onClick,
  iconOnly = false,
  fullWidth = false,
  lpUi,
  shipmentId,
}: {
  onClick?: any;
  iconOnly?: boolean;
  fullWidth?: boolean;
  lpUi?: string;
  shipmentId?: string;
}) => {
  const { t, i18n } = useTranslation(['shipments', 'products']);
  const navigate = useNavigate();
  const { partnerId } = useParams();
  const currentLanguage = i18n.language.toUpperCase();

  const handleClickGoTo = () => {
    const queryparam = {
      partnerId: partnerId,
      ...(lpUi ? { lpUi: lpUi } : {}),
      ...(shipmentId ? { shipmentId: shipmentId } : {}),
    };
    const path = lpUi ? PATHS.PRODUCT.DETAIL : PATHS.SHIPMENT.REMOTE_CONTROL;

    navigate(generatePath(path, queryparam));
  };

  return (
    <Tooltip
      data-testid="remote-control-tooltip-container"
      id="remote-control-tooltip"
      placement="bottom"
      enterDelay={200}
      title={
        <Stack gap="0.5rem" px=".75rem">
          <Text variant="titleM">{t('shipments:remoteControlInfo.title')}</Text>
          <Text variant="bodyTextS">
            {t('shipments:remoteControlInfo.description')}
          </Text>
          <Button
            variant="links"
            onClick={e => {
              window.open(
                pdfLink[currentLanguage],
                '_blank',
                'noopener noreferrer'
              );
              e.stopPropagation();
            }}
            sx={{
              textAlign: 'left',
              '& span': {
                paddingLeft: '0 !important',
                paddingRight: '0 !important',
                '& h5': {
                  paddingLeft: '0 !important',
                  paddingRight: '0 !important',
                },
              },
            }}
          >
            <Text variant="titleS" sx={{ width: '100%' }}>
              {t('shipments:remoteControlInfo.moreLabel')}
            </Text>
          </Button>
        </Stack>
      }
      sx={{
        maxWidth: '16.0625rem',
      }}
      onClick={onClick}
    >
      {iconOnly ? (
        <ControlIconWrap
          data-testid="remote-control-tooltip-icon"
          size="1.5rem"
        />
      ) : (
        <Button
          variant="primary"
          data-testid="remote-control-tooltip-button"
          icon={ControlIcon}
          onClick={handleClickGoTo}
          fullWidth={fullWidth}
        >
          {t('products:detail.remoteControlCTA')}
        </Button>
      )}
    </Tooltip>
  );
};
