// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { GenericError } from 'components/errors/GenericError';
import Error404 from 'components/views/lpAccount/Error404';
import { NotFound404Error } from 'core/errors/CustomAPIError';
import { Loading } from 'features/account';
import useToastMessages from 'hooks/useToastMessages';
import { useTranslation } from 'react-i18next';
import { filterEditableValues } from 'utils/filterEditableValues';

import { useGetMember } from '../api/getMember';
import { useUpdateMember } from '../api/updateMember';
import Layout from '../components/Layout';
import { MemberForm } from '../components/MemberForm';
import { IMemberForm, MEMBER_FORM_KEYS } from '../types';

export const UpdateMember = ({
  backToMembersList,
}: {
  backToMembersList: () => void;
}) => {
  const { t } = useTranslation(['general', 'userManagement', 'messages']);

  const { error: toastError, success: toastSuccess } = useToastMessages();

  const { mutateAsync: updateMember } = useUpdateMember();
  const { data: member, isPending, error } = useGetMember();

  const onSubmit = async (data: IMemberForm) => {
    try {
      await updateMember(data);

      toastSuccess('messages:partnerMemberEditSuccess.message');
      backToMembersList();
    } catch (error: unknown) {
      toastError('messages:partnerMemberEditError.message');
    }
  };

  if (error) {
    console.error(error.message);

    if (error instanceof NotFound404Error) {
      return <Error404 />;
    }

    return <GenericError />;
  }

  let children;

  if (isPending) {
    children = <Loading />;
  }

  if (member) {
    children = (
      <MemberForm
        defaultValues={
          filterEditableValues({
            values: member,
            fields: MEMBER_FORM_KEYS,
          }) as IMemberForm
        }
        onSubmit={onSubmit}
        backToMembersList={backToMembersList}
      />
    );
  }

  const memberName = member ? (
    member.firstName + ' ' + member.lastName
  ) : (
    <span>&nbsp;</span>
  );

  return (
    <Layout
      path={t('userManagement:edit.breadcrumb', { memberName })}
      subtitle={t('userManagement:create.title')}
      title={memberName}
    >
      {children}
    </Layout>
  );
};
