// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { NewCard as Card } from '@livingpackets/design-system-react-next';
import Header from 'components/layout/Header';
import { PATHS } from 'configs';
import { generatePath, useNavigate } from 'react-router-dom';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';

type Props = {
  children: React.ReactNode;
  path: string;
  subtitle: string;
  title: string | React.ReactNode;
};

const Layout = ({ children, path, subtitle, title }: Props) => {
  const navigate = useNavigate();

  const activePartner = useMyPartnersStore(activePartnerSelector);

  return (
    <Card
      headerProps={{
        variant: 'black',
        padding: '1.5rem',
        children: (
          <Header
            callback={() =>
              navigate(
                generatePath(PATHS.USER_MANAGEMENT.LIST, {
                  partnerId: activePartner.id,
                })
              )
            }
            path={path}
            subtitle={subtitle}
            title={title}
          />
        ),
      }}
      bodyProps={{ children }}
    />
  );
};

export default Layout;
