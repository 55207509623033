// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useMemo } from 'react';

import { NewCard as Card } from '@livingpackets/design-system-react-next';
import { GenericError } from 'components/errors/GenericError';
import Error404 from 'components/views/lpAccount/Error404';
import { PATHS } from 'configs';
import { NotFound404Error } from 'core/errors/CustomAPIError';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { formatLpui } from 'utils/product';

import Header from '../../../components/layout/Header';
import { useProduct } from '../api/getProduct';
import ProductDetails from '../components/product';
import { ProductTypeEnum } from '../types';

export const Product = () => {
  const { partnerId, lpUi } = useParams<{
    partnerId: string;
    lpUi: string;
  }>();
  const { t } = useTranslation('products');
  const navigate = useNavigate();

  const { data: product, error, isPending } = useProduct();

  const headerTitle = useMemo(() => {
    if (product?.productType === ProductTypeEnum.BOX) {
      return t('detail.title.box');
    }
    if (product?.productType === ProductTypeEnum.BOX_PRO) {
      return t('detail.title.boxPro');
    }
    if (product?.productType === ProductTypeEnum.TABLET) {
      return t('detail.title.tablet');
    }

    return '';
  }, [product, t]);

  if (error) {
    console.error(error.message);

    if (error instanceof NotFound404Error) {
      return <Error404 />;
    }

    return <GenericError />;
  }

  return (
    <Card
      headerProps={{
        variant: 'black',
        padding: '1.5rem',
        children: (
          <Header
            product={product}
            callback={() =>
              navigate(generatePath(PATHS.PRODUCT.LIST, { partnerId }))
            }
            path={t('detail.overview')}
            subtitle={formatLpui(lpUi)}
            title={headerTitle}
          />
        ),
      }}
      bodyProps={{
        children: <ProductDetails isPending={isPending} product={product} />,
      }}
    />
  );
};
