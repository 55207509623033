// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useCallback, useEffect, useState } from 'react';

import { Card, CardBody, CardHeader } from '@livingpackets/design-system-react';
import {
  ArrowLeftIcon,
  ButtonV2 as Button,
  Stack,
  styled,
  Text,
} from '@livingpackets/design-system-react-next';
import ProductDetails from 'components/organisms/ProductDetails';
import { PATHS } from 'configs';
import { ProductTypeEnum, ProductAPI } from 'features/products';
import useProductDetail, { useProductStore } from 'hooks/useProductDetail';
import useShipmentDetail from 'hooks/useShipmentDetail';
import { IShipment } from 'models/shipment';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';
import { formatLpui } from 'utils/product';

const CardBoxDetail = styled(Card)`
  width: 956px;

  @media (max-width: ${({ theme }) => theme.mediaBreakpoints.md}) {
    width: auto;
  }
`;
const CardBoxHeader = styled(CardHeader)`
  background-color: ${({ theme }) => theme.palette.custom.neutral.black[100]};
  color: ${({ theme }) => theme.palette.custom.neutral.white.pure};
  border-radius: 20px 20px 0 0;
  margin-bottom: -15px;
`;

const PartnerProductsDetail = () => {
  const { t } = useTranslation('products');

  const navigate = useNavigate();
  const activePartner = useMyPartnersStore(activePartnerSelector);
  const { lpUi } = useParams<{ lpUi: string }>();

  const { getProductDetail } = useProductDetail();
  const { getShipmentDetail } = useShipmentDetail();

  const [productData, setProductData] = useState<ProductAPI>();
  const [ShipmentDetail, setShipmentDetail] = useState<IShipment>();

  const initProductData = useCallback(
    (product: ProductAPI) => {
      setProductData(product);
    },
    [setProductData]
  );

  const setProduct = useProductStore(
    (state: { setProduct: any }) => state.setProduct
  );

  useEffect(() => {
    if (!productData && lpUi) {
      getProductDetail(activePartner.id, lpUi).then(({ success, product }) => {
        if (!success || !product) {
          navigate(PATHS.PAGE_404);
        } else {
          initProductData(product);
          if (product.last_shipment_id) {
            getShipmentDetail(activePartner.id, product.last_shipment_id).then(
              ({ success, shipment }) => {
                if (success) {
                  setShipmentDetail(shipment);
                }
              }
            );
          }
        }
      });
    }

    return () => setProduct();
  }, [
    getProductDetail,
    activePartner,
    lpUi,
    navigate,
    setProduct,
    productData,
    initProductData,
    getShipmentDetail,
  ]);

  const backToBoxList = useCallback(() => {
    navigate(
      generatePath(PATHS.PRODUCT.LIST, {
        partnerId: activePartner.id,
      })
    );
  }, [navigate, activePartner]);

  const getProductDetailTitleText = useCallback(
    (product: ProductAPI) => {
      if (product.productType === ProductTypeEnum.BOX) {
        return t('detail.title.box');
      }

      if (product.productType === ProductTypeEnum.BOX_PRO) {
        return t('detail.title.boxPro');
      }
      if (product.productType === ProductTypeEnum.TABLET) {
        return t('detail.title.tablet');
      }

      return '';
    },
    [t]
  );

  return (
    <CardBoxDetail>
      <React.Fragment key=".0">
        <CardBoxHeader padding={5}>
          <Stack direction="row" alignItems="center" spacing=".75rem">
            <Button
              variant="tertiary"
              icon={ArrowLeftIcon}
              onClick={backToBoxList}
            />
            <Text variant="bodyTextXS" color="custom.neutral.black.50">
              {t('detail.overview')}
            </Text>
          </Stack>
          {productData && (
            <>
              <Text
                variant="titleXS"
                mt="1rem"
                color="custom.neutral.white.pure"
              >
                {formatLpui(productData.lp_ui)}
              </Text>
              <Text
                variant="titleL"
                mt="10px"
                mb="15px"
                color="custom.neutral.white.pure"
              >
                {getProductDetailTitleText(productData)}
              </Text>
            </>
          )}
        </CardBoxHeader>
        <CardBody paddingLeft="32px" paddingRight="32px" paddingTop="36px">
          {productData && (
            <ProductDetails
              product={productData}
              shipmentDetail={ShipmentDetail}
            />
          )}
        </CardBody>
      </React.Fragment>
    </CardBoxDetail>
  );
};

export default PartnerProductsDetail;
