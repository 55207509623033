// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import PartnerInChargeProducts from 'components/organisms/PartnerInChargeProducts';
import PartnerProductsDetail from 'components/views/lpVillage/PartnerProductsDetail';
import { Navigate, Route, Routes } from 'react-router-dom';

import { Product } from './Product';

// http://localhost:3000/partner/:partnerId/products/:lpui/detail

/**
 * Base URL: /partner/:partnerId/products/*
 */
export const PRODUCTS_ROOT = '/products';

export const ProductsRoutes = () => (
  <Routes>
    <Route
      path=""
      index
      element={<PartnerInChargeProducts />} // Later changes with <Products />
    />
    <Route path=":lpUi/detail" element={<Product />} />
    <Route path=":lpUi/detail_old" element={<PartnerProductsDetail />} />
    <Route path="*" element={<Navigate to="." />} />
  </Routes>
);
