// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useContext, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  ButtonV2 as Button,
  DeleteIcon,
  Grid,
  ModalV2,
  Text,
  NewTextField as TextField,
} from '@livingpackets/design-system-react-next';
import { UserBusinessTypeEnum } from 'enums/UserBusinessTypeEnum';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useAppState, { userSelector } from 'stores/appState/useAppState';
import * as yup from 'yup';

import { ErrorDeleteAccount } from './ErrorDeleteAccount';
import { ErrorDeleteAccountContactSupport } from './ErrorDeleteAccountContactSupport';
import { useDeleteAccount } from '../../../../api/personal/deleteAccount';
import { ModalSizeContext } from '../../../../routes/Personal';

export const RGPD_MORE_INFO_LINK = 'https://livingpackets.com/privacy/en';

const validationSchema = (confirmDeleteKeyword: string) =>
  yup.object().shape({
    confirmDelete: yup
      .string()
      .required()
      .matches(new RegExp(`^${confirmDeleteKeyword}$`)),
  });

type FormValues = {
  confirmDelete: string;
};

const defaultValues = { confirmDelete: '' };

export const CanDeleteModal = ({ open, setOpen }: any) => {
  const { t } = useTranslation('account');

  const [globalFailureCount, setGlobalFailureCount] = useState(0);

  // In personal acocunt no context is provided so we need a default {} to avoid errors
  const { width, handleResize } = useContext(ModalSizeContext) || {};

  const { businessType } = useAppState(userSelector)!;

  const isPersonalAccount = businessType === UserBusinessTypeEnum.PERSONAL;

  useEffect(() => {
    if (open && handleResize) {
      handleResize();
    }
  }, [open, handleResize]);

  const {
    handleSubmit,
    control,
    reset: resetForm,
    formState: { isValid },
  } = useForm<FormValues>({
    resolver: yupResolver(
      validationSchema(t('personal.deletion.canDeleteKeywordToType'))
    ),
    defaultValues,
    mode: 'onChange',
  });

  const {
    mutate: handleDelete,
    isPending,
    isError,
    reset: resetMutation,
    failureCount,
  } = useDeleteAccount();

  // Reset form when modal is closed
  useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open, resetForm]);

  // Use a global failure count because the one from the mutation is reset on retry
  useEffect(() => {
    if (failureCount === 0) return;

    setGlobalFailureCount(prev => prev + 1);
  }, [failureCount]);

  if (globalFailureCount >= 2) {
    return <ErrorDeleteAccountContactSupport open={open} setOpen={setOpen} />;
  }

  if (isError) {
    const retry = () => {
      resetMutation();
      resetForm();
    };

    return (
      <ErrorDeleteAccount
        open={open}
        setOpen={() => {
          retry();
          setOpen(false);
        }}
        retry={retry}
      />
    );
  }

  return (
    <ModalV2
      open={open}
      setOpen={setOpen}
      sx={{
        '& .MuiPaper-root': {
          width,
        },
      }}
    >
      <Grid container direction="column" gap="2rem">
        <Grid item mobile={12}>
          <Grid container direction="column" gap=".25rem">
            <Text variant="titleXL">{t('personal.deletion.title')}</Text>
            <Text variant="bodyTextM">{t('personal.deletion.subtitle')}</Text>
          </Grid>
        </Grid>
        <Grid item mobile={12}>
          <Grid container direction="column" gap=".875rem">
            <Grid item mobile={12}>
              <Text variant="titleM" color="custom.neutral.black.50">
                {t('personal.deletion.instructions')}
              </Text>
            </Grid>
            <Grid item mobile={12}>
              <Grid
                container
                direction="column"
                gap="1.5rem"
                sx={{
                  bgcolor: 'custom.neutral.black.2',
                  borderRadius: '1.25rem',
                  padding: '1.5rem',
                }}
              >
                <Text variant="bodyTextM">
                  {t('personal.deletion.info1')}
                  <br />
                  <br />
                  {t('personal.deletion.info2')}
                  {!isPersonalAccount && ` ${t('personal.deletion.info3')}`}
                </Text>
                <Text
                  variant="titleS"
                  component="a"
                  color="primary.main"
                  href={RGPD_MORE_INFO_LINK}
                  target="_blank"
                >
                  {t('personal.deletion.gdprLink')}
                </Text>
                <Grid container direction="column" gap=".5rem">
                  <Text variant="bodyTextM">
                    {t('personal.deletion.canDelete')}
                  </Text>
                  <Controller
                    name="confirmDelete"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        data-testid="delete-account-confirm-input"
                        label={t('personal.deletion.canDeleteInputLabel')}
                        {...field}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between">
          <Button
            variant="secondary"
            onClick={() => setOpen(false)}
            data-testid="delete-account-cancel-button"
          >
            {t('personal.deletion.cancelButton')}
          </Button>
          <Button
            data-testid="delete-account-confirm-button"
            disabled={!isValid || isPending}
            variant="primary"
            color="critical"
            icon={DeleteIcon}
            onClick={handleSubmit(handleDelete as any)}
          >
            {t('personal.deletion.deleteButton')}
          </Button>
        </Grid>
      </Grid>
    </ModalV2>
  );
};
