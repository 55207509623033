// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useState } from 'react';

import { Grid } from '@livingpackets/design-system-react-next';
import Error404 from 'components/views/lpAccount/Error404';
import { Loading } from 'features/account';
import { ProductEntity } from 'features/products';
import { useTranslation } from 'react-i18next';

import LeftColumn from './LeftColumn';
import ProductScreenModal from './ProductScreenModal';
import RightColumn from './RightColumn';
import useProductDetails from '../../hooks/useProductDetails';

type Props = {
  product?: ProductEntity;
  isPending: Boolean;
};

const ProductDetails = ({ product, isPending }: Props) => {
  const { t } = useTranslation('products');

  // Product screen modal state
  const [isOpen, setIsOpen] = useState(false);

  const { imgSrc, productScreenImageBase64, productClosingStateText } =
    useProductDetails(product);

  if (isPending) {
    return <Loading />;
  }

  if (!product) {
    return <Error404 />;
  }

  return (
    <>
      <Grid container spacing={4}>
        <Grid
          item
          mobile={12}
          tablet={4}
          maxWidth={{ mobile: 'none', tablet: '16.625rem' }}
        >
          <LeftColumn
            product={product}
            imgSrc={imgSrc}
            setIsOpen={setIsOpen}
            productScreenImageBase64={productScreenImageBase64}
          />
        </Grid>
        <Grid item mobile={12} tablet={8}>
          <RightColumn
            product={product}
            productClosingStateText={productClosingStateText}
          />
        </Grid>
      </Grid>
      {productScreenImageBase64 && (
        <ProductScreenModal
          open={isOpen}
          setOpen={setIsOpen}
          productScreenUrl={productScreenImageBase64}
          headerLabel={t('detail.productScreenHeaderLabel')}
        />
      )}
    </>
  );
};

export default ProductDetails;
