// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useCallback, useEffect, useState } from 'react';

import {
  AddIcon,
  Box,
  ButtonV2 as Button,
  Text,
  useTheme,
  Stack,
  Grid,
  NewCard as Card,
  Chip,
  AccountIcon,
} from '@livingpackets/design-system-react-next';
import HeadbandOnboardingDe from 'assets/headband-onboarding-de.png';
import HeadbandOnboardingEn from 'assets/headband-onboarding-en.png';
import HeadbandOnboardingFr from 'assets/headband-onboarding-fr.png';
import HomeInfoCard from 'components/molecules/HomeInfoCard';
import HomeShipmentCard from 'components/molecules/HomeShipmentCard';
import Title from 'components/molecules/Title';
import { PATHS } from 'configs';
import {
  PartnerBusinessStatusEnum,
  PartnerBusinessTypeEnum,
} from 'enums/accountTypeEnum';
import { AlertsDashboard } from 'features/alertsDashboard';
import { Onboarding as OnboardingModule } from 'features/onboarding';
import useCurrentPartner from 'hooks/useCurrentPartner';
import useIntlFormatTimestamp from 'hooks/useIntlFormatTimestamp';
import useNavigatePartner from 'hooks/useNavigatePartner';
import useNewShipmentDisabled from 'hooks/useNewShipmentDisabled';
import i18next from 'i18next';
import { find } from 'lodash/fp';
import { Trans, useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import useAppState, { userSelector } from 'stores/appState/useAppState';
import useCurrentPartnerStore, {
  currentPartnerSelector,
  registrationAddressSelector,
} from 'stores/useCurrentPartnerStore';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';

const bannerHeadband = {
  de: HeadbandOnboardingDe,
  en: HeadbandOnboardingEn,
  fr: HeadbandOnboardingFr,
} as const;

const PartnerHome = () => {
  const theme = useTheme();

  const { t } = useTranslation(['general', 'partnerSettings', 'dashboard']);
  const currentLanguage = i18next.language;
  const navigate = useNavigate();

  const activePartner = useMyPartnersStore(activePartnerSelector);
  const partner = useCurrentPartnerStore(currentPartnerSelector);

  const { reset, getCurrentPartner, getRegistrationAddress } =
    useCurrentPartner();
  const appUser = useAppState(userSelector);

  const formatTimestamp = useIntlFormatTimestamp();
  const navigatePartner = useNavigatePartner();

  const [displayBanner, setDisplayBanner] = useState<boolean>(false);
  const [bannerContent, setBannerContent] = useState<string>('');

  const [update, setUpdate] = useState<number>(0);
  const [showOnboarding, setShowOnboarding] = useState(false);

  const manageBanner = useCallback(
    (status: PartnerBusinessStatusEnum, type: PartnerBusinessTypeEnum) => {
      if ([PartnerBusinessStatusEnum.BLOCKED].includes(status)) {
        setDisplayBanner(true);
        setBannerContent(status.toLocaleLowerCase());
      } else if (
        status === PartnerBusinessStatusEnum.PENDING &&
        type === PartnerBusinessTypeEnum.PRO &&
        find({ name: 'update:partner' }, activePartner.role.scopes)
      ) {
        setDisplayBanner(true);
        setBannerContent(status.toLocaleLowerCase());
      } else {
        setDisplayBanner(false);
        setBannerContent('');
      }
    },
    [activePartner.role.scopes]
  );

  useEffect(() => {
    getRegistrationAddress(activePartner.id);
    getCurrentPartner(activePartner.id);

    return () => {
      reset();
    };
  }, [activePartner.id, getRegistrationAddress, reset, getCurrentPartner]);

  useEffect(() => {
    manageBanner(
      partner.businessInformation.status,
      partner.businessInformation.type
    );
  }, [bannerContent, displayBanner, manageBanner, partner]);

  const registrationAddress = useCurrentPartnerStore(
    registrationAddressSelector
  );

  const isPartnerPending =
    partner.businessInformation.status === PartnerBusinessStatusEnum.PENDING;

  const disableNewShipment = useNewShipmentDisabled(partner);

  return (
    <>
      <Grid container flexDirection="column" gap="2rem">
        <Grid item sx={{ order: 1 }}>
          {/* ------ TITLE ------- */}
          <Title pageName={t('general:dashboard')} mb="0" />
        </Grid>
        <Grid item sx={{ order: 1 }}>
          {/* ACCOUNT STATE */}
          {(!partner.isPartnerIDCardCompleted || isPartnerPending) && (
            <Box>
              <Card
                headerProps={{
                  minHeight: '6.25rem',
                  children: (
                    <Stack
                      direction="row"
                      gap="0.625rem"
                      alignItems="center"
                      alignContent="center"
                    >
                      <Text variant="titleL" color="custom.neutral.white.pure">
                        {t('dashboard:accountWarningMessage.titleCard', {
                          username: `${appUser?.firstName} ${appUser?.lastName}`,
                        })}
                      </Text>
                      {partner.businessInformation.status ===
                        PartnerBusinessStatusEnum.PENDING && (
                        <Chip
                          type="tags"
                          label={t(
                            'dashboard:accountWarningMessage.titleChipLabel'
                          )}
                          variant="titleXS"
                          icon={
                            <AccountIcon
                              color={theme.palette.custom.secondary.yellow[100]}
                            />
                          }
                          sx={{
                            backgroundColor:
                              theme.palette.custom.secondary.yellow[10],
                            '.MuiChip-label .MuiTypography-root': {
                              color: `${theme.palette.custom.secondary.yellow[100]} !important`,
                            },

                            height: '2rem',
                          }}
                        />
                      )}
                    </Stack>
                  ),
                }}
                bodyProps={{
                  children: (
                    <Grid
                      container
                      spacing="2rem"
                      justifyContent="space-between"
                      wrap="nowrap"
                    >
                      <Grid
                        item
                        mobile={partner.isPartnerIDCardCompleted ? 12 : 8}
                      >
                        <Text variant="titleL" paddingBottom=".5rem">
                          {partner.isPartnerIDCardCompleted
                            ? t(
                                'dashboard:accountWarningMessage.titleMessageCompleted'
                              )
                            : t(
                                'dashboard:accountWarningMessage.titleMessageNotCompleted'
                              )}
                        </Text>
                        <Text
                          variant="bodyTextM"
                          color={theme.palette.custom.neutral.black[50]}
                        >
                          <Trans
                            t={t}
                            i18nKey={
                              partner.isPartnerIDCardCompleted
                                ? 'dashboard:accountWarningMessage.descriptionAccountCompleted'
                                : 'dashboard:accountWarningMessage.descriptionAccountNotCompleted'
                            }
                            components={{
                              1: (
                                <span
                                  style={{
                                    fontWeight: 'bold',
                                  }}
                                />
                              ),
                            }}
                          />
                        </Text>
                      </Grid>

                      {!partner.isPartnerIDCardCompleted && (
                        <Grid item mobile={4} tablet="auto">
                          <Button
                            size="large"
                            onClick={() => {
                              navigatePartner(
                                '/account/partnership/information'
                              );
                            }}
                          >
                            {t('dashboard:accountWarningMessage.btnLabel')}
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  ),
                }}
              />
            </Box>
          )}
        </Grid>
        {/* ------ ONBORDING TUTORIAL ------- */}
        <Grid
          item
          sx={{
            order:
              !partner.isPartnerIDCardCompleted || isPartnerPending ? 2 : 5,
          }}
        >
          <Box
            height="13.625rem"
            borderRadius=".5rem"
            sx={{
              backgroundImage: `url(${
                bannerHeadband[
                  currentLanguage.toLowerCase() as 'de' | 'en' | 'fr'
                ]
              })`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
            justifyContent="flex-end"
          >
            <Button
              variant="secondary"
              sx={{ marginRight: '2rem', marginBottom: '1.4375rem' }}
              onClick={() => setShowOnboarding(true)}
            >
              {t('dashboard:tutorial.btnLabel')}
            </Button>
          </Box>
        </Grid>
        {/* ------ OVERVIEW ----- */}
        <Grid item sx={{ order: 3 }}>
          <Stack direction="row">
            <Stack gap="0.875rem" width="100%">
              <Box display="flex" justifyContent="space-between">
                <Stack direction="row" gap="0.75rem" alignItems="center">
                  <Text variant="titleL">
                    {t('dashboard:overviewSection.title')}
                  </Text>

                  {!partner.isPartnerIDCardCompleted && (
                    <Chip
                      label={t('dashboard:createShipment.tagInformation', {
                        profil: partner.businessInformation.status,
                        maxOfShipements: partner.maxNbOfShipments,
                      })}
                      type="tags"
                      sx={{ textTransform: 'uppercase' }}
                    />
                  )}
                </Stack>

                <Button
                  variant={
                    partner.isPartnerIDCardCompleted ? 'primary' : 'secondary'
                  }
                  textVariant="titleM"
                  icon={AddIcon}
                  size="medium"
                  disabled={disableNewShipment}
                  onClick={() => {
                    navigate(
                      generatePath(PATHS.SHIPMENT.LABEL_IMPORT, {
                        partnerId: activePartner.id,
                      })
                    );
                  }}
                >
                  {t('dashboard:createShipment.btnLabel')}
                </Button>
              </Box>

              <Stack direction="row" alignItems="baseline" gap="0.125rem">
                <Text variant="bodyTextS">
                  {t('dashboard:overviewSection.lastUpdate')}
                </Text>
                {update > 0 && (
                  <Text variant="titleXS" color="custom.neutral.black.50">
                    {formatTimestamp(update, {
                      day: 'numeric',
                      month: 'numeric',
                      year: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                    })}
                  </Text>
                )}
              </Stack>
              <HomeShipmentCard setUpdate={setUpdate} />
            </Stack>
          </Stack>
        </Grid>

        {/* ------ OVERVIEW ----- */}
        <Grid item sx={{ order: 4 }}>
          <AlertsDashboard />
        </Grid>

        {/* ------ PARTNER INFO ----- */}
        <Grid item sx={{ order: 4 }}>
          <Stack gap="0.875rem">
            <Text variant="titleL">{t('partnerSettings:partnerInfo')}</Text>
            <HomeInfoCard
              user={appUser}
              subtitle={activePartner.name}
              address={registrationAddress}
              website={partner.website}
              phoneNumber={partner.phoneNumber}
              editLink={generatePath(PATHS.SETTINGS_PARTNER_PARTNERSHIP, {
                partnerId: activePartner.id,
              })}
            />
          </Stack>
        </Grid>
      </Grid>

      {showOnboarding && (
        <OnboardingModule onClose={() => setShowOnboarding(false)} />
      )}
    </>
  );
};

export default PartnerHome;
