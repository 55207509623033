// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  ButtonV2 as Button,
  Grid,
  Select,
  Stack,
  Text,
  NewTextField as TextField,
} from '@livingpackets/design-system-react-next';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { memberSchema } from 'schemas/memberSchema';

import { IMemberForm, INITIAL_STATE_MEMBER_FORM } from '../types';

type Props = {
  defaultValues?: IMemberForm;
  onSubmit: (data: IMemberForm) => void;
  backToMembersList: () => void;
};

export const MemberForm = ({
  defaultValues,
  onSubmit,
  backToMembersList,
}: Props) => {
  const { t } = useTranslation(['userManagement', 'general']);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = useForm<IMemberForm>({
    defaultValues: defaultValues || INITIAL_STATE_MEMBER_FORM,
    resolver: yupResolver(memberSchema()),
    mode: 'onTouched',
  });

  const memberRolesOptions: Array<{
    value: string | number;
    label: string;
  }> = useMemo(() => {
    const roleList = [];

    if (process.env.REACT_APP_PARTNER_USER_ROLE_BASIC_ID) {
      roleList.push({
        value: process.env.REACT_APP_PARTNER_USER_ROLE_BASIC_ID,
        label: t('userManagement:form.role.choice.basic'),
      });
    }

    if (process.env.REACT_APP_PARTNER_USER_ROLE_PARTNER_ADMIN_ID) {
      roleList.push({
        value: process.env.REACT_APP_PARTNER_USER_ROLE_PARTNER_ADMIN_ID,
        label: t('userManagement:form.role.choice.partnerAdmin'),
      });
    }

    return roleList;
  }, [t]);

  return (
    <Grid container flexDirection="column" spacing="2rem">
      <Grid item container mobile={12} spacing="1rem">
        <Grid item container justifyContent="space-between" gap=".5rem">
          <Stack gap=".5rem">
            <Text variant="titleL">
              {t('userManagement:create.block.basicInformation.title')}
            </Text>
            <Text variant="titleXS" color="custom.neutral.black.50">
              {t('userManagement:create.block.basicInformation.subTitle')}
            </Text>
          </Stack>
          <Text variant="titleXS" color="custom.neutral.black.50">
            {t('userManagement:create.block.basicInformation.requiredFields')}
          </Text>
        </Grid>
        <Grid item mobile={12}>
          <Grid container spacing="1rem">
            <Grid item mobile={12} tablet={6}>
              <Controller
                name="firstName"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    required
                    label={t('userManagement:form.firstName.placeholder')}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item mobile={12} tablet={6}>
              <Controller
                name="lastName"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    required
                    fullWidth
                    label={t('userManagement:form.lastName.placeholder')}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item mobile={12} tablet={6}>
              <Controller
                name="email"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    required
                    label={t('userManagement:form.email.placeholder')}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    disabled={!!defaultValues}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container mobile={12} spacing="1rem">
        <Grid item mobile={12}>
          <Grid container direction="column" gap="0.5rem">
            <Text variant="titleL">
              {t('userManagement:create.block.role.title')}
            </Text>
            <Text variant="titleXS" color="custom.neutral.black.50">
              {t('userManagement:create.block.role.subTitle')}
            </Text>
          </Grid>
        </Grid>
        <Grid item mobile={12} tablet={6}>
          <Controller
            name="roleId"
            control={control}
            render={({ field }) => (
              <Select
                id="roleId"
                required
                label={t('userManagement:form.role.placeholder')}
                options={memberRolesOptions}
                {...field}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid
        item
        container
        mobile={12}
        justifyContent={defaultValues ? 'space-between' : 'flex-end'}
      >
        {!!defaultValues && (
          <Button
            variant="secondary"
            data-testid="cancelBtn"
            onClick={backToMembersList}
          >
            {t('general:cancel')}
          </Button>
        )}
        <Button
          data-testid="submitBtn"
          disabled={!isValid || isSubmitting}
          onClick={handleSubmit(onSubmit)}
        >
          {t('userManagement:form.submit.label')}
        </Button>
      </Grid>
    </Grid>
  );
};
