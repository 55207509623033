// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

export const INITIAL_STATE_MEMBER_FORM: IMemberForm = {
  firstName: '',
  lastName: '',
  email: '',
  roleId: '',
};

export interface IMemberForm {
  firstName: string;
  lastName: string;
  email: string;
  roleId: string;
}

export const MEMBER_FORM_KEYS = ['firstName', 'lastName', 'email', 'roleId'];
