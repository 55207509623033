// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { SentryCaptureException } from 'helpers/tracking';

import { IRole, MemberAPI } from '../api/models/memberAPI';

export class MemberEntity {
  static create(data: MemberAPI): MemberEntity {
    try {
      // Validate data to ensure it matches the MemberAPI type
      if (!data || typeof data !== 'object') {
        throw new Error('Invalid data: data must be an object');
      }

      return new MemberEntity(data);
    } catch (error: any) {
      SentryCaptureException(error, null);

      throw new Error(`Failed to create MemberEntity: ${error.message}`);
    }
  }

  accountId: string;
  createdAt: number;
  createdBy: string;
  email: string;
  firstName: string;
  lastName: string;
  partnerId: string;
  role: IRole;
  roleId: string;
  updatedAt: number;

  constructor(data: MemberAPI) {
    this.accountId = data.accountId;
    this.createdAt = data.createdAt;
    this.createdBy = data.createdBy;
    this.email = data.email;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.partnerId = data.partnerId;
    this.role = data.role;
    this.roleId = data.roleId;
    this.updatedAt = data.updatedAt;
  }
}
