// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useCallback } from 'react';

import { ProductListDisplayTypeEnum } from 'components/organisms/PartnerInChargeProducts';
import { ProductTypeEnum } from 'features/products';

const productListProductTypeSelectedCurrentKey =
  'productListProductTypeSelectedCurrent';
const productListProductTypeSelectedCurrentDefaultValue = ProductTypeEnum.BOX;
const productListPaginationCurrentKey = 'productListPaginationCurrent';
const productListPaginationCurrentDefaultValue = {
  pageSize: 6,
  orderDirection: 'DESC',
  orderColumn: 'updated_at',
  page: 1,
};
const productListSearchCurrentKey = 'productListSearchCurrent';
const productListSearchCurrentDefaultValue = '';
const productListDisplayTypeSelectedCurrentKey =
  'productListDisplayTypeSelectedCurrent';

const useProductList = () => {
  const getProductListProductTypeSelectedCurrent = useCallback(
    (): ProductTypeEnum =>
      sessionStorage.getItem(productListProductTypeSelectedCurrentKey)
        ? (sessionStorage.getItem(
            productListProductTypeSelectedCurrentKey
          ) as ProductTypeEnum)
        : productListProductTypeSelectedCurrentDefaultValue,
    []
  );

  const setProductListProductTypeSelectedCurrent = useCallback(
    (productListProductTypeSelected: ProductTypeEnum) => {
      sessionStorage.setItem(
        productListProductTypeSelectedCurrentKey,
        productListProductTypeSelected
      );
    },
    []
  );

  const resetProductListProductTypeSelectedCurrent = useCallback(() => {
    sessionStorage.setItem(
      productListProductTypeSelectedCurrentKey,
      productListProductTypeSelectedCurrentDefaultValue
    );
  }, []);

  const getProductListPaginationCurrent = useCallback(
    () =>
      sessionStorage.getItem(productListPaginationCurrentKey)
        ? JSON.parse(
            sessionStorage.getItem(productListPaginationCurrentKey) as string
          )
        : productListPaginationCurrentDefaultValue,
    []
  );

  const setProductListPaginationCurrent = useCallback(
    (productListPaginationInfo: any) => {
      sessionStorage.setItem(
        productListPaginationCurrentKey,
        JSON.stringify(productListPaginationInfo)
      );
    },
    []
  );

  const resetProductListPaginationCurrent = useCallback(() => {
    sessionStorage.setItem(
      productListPaginationCurrentKey,
      JSON.stringify(productListPaginationCurrentDefaultValue)
    );
  }, []);

  const getProductListSearchCurrent = useCallback(
    (): string =>
      sessionStorage.getItem(productListSearchCurrentKey)
        ? (sessionStorage.getItem(productListSearchCurrentKey) as string)
        : productListSearchCurrentDefaultValue,
    []
  );

  const setProductListSearchCurrent = useCallback(
    (productListSearch: string) => {
      sessionStorage.setItem(productListSearchCurrentKey, productListSearch);
    },
    []
  );

  const resetProductListSearchCurrent = useCallback(() => {
    sessionStorage.setItem(
      productListSearchCurrentKey,
      productListSearchCurrentDefaultValue
    );
  }, []);

  const getProductListDisplayTypeSelectedCurrent = useCallback(
    (): ProductListDisplayTypeEnum =>
      sessionStorage.getItem(productListDisplayTypeSelectedCurrentKey)
        ? (sessionStorage.getItem(
            productListDisplayTypeSelectedCurrentKey
          ) as ProductListDisplayTypeEnum)
        : ProductListDisplayTypeEnum.MAP,
    []
  );

  const setProductListDisplayTypeSelectedCurrent = useCallback(
    (productListDisplayTypeSelected: ProductListDisplayTypeEnum) => {
      sessionStorage.setItem(
        productListDisplayTypeSelectedCurrentKey,
        productListDisplayTypeSelected
      );
    },
    []
  );

  return {
    getProductListProductTypeSelectedCurrent,
    setProductListProductTypeSelectedCurrent,
    resetProductListProductTypeSelectedCurrent,
    getProductListPaginationCurrent,
    setProductListPaginationCurrent,
    resetProductListPaginationCurrent,
    getProductListSearchCurrent,
    setProductListSearchCurrent,
    resetProductListSearchCurrent,
    getProductListDisplayTypeSelectedCurrent,
    setProductListDisplayTypeSelectedCurrent,
  };
};

export default useProductList;
