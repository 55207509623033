// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  Box,
  Text,
  EmptyState,
  ButtonV2 as Button,
  BulletWrongIcon,
  SearchIcon,
  MapIcon,
  BulletListIcon,
  styled,
  useTheme,
  Stack,
  NewTextField as TextField,
} from '@livingpackets/design-system-react-next';
import MarkerBoxAvailableImg from 'assets/img/map/product/marker/marker-box-available.png';
import MarkerBoxInShipmentImg from 'assets/img/map/product/marker/marker-box-in-shipment.png';
import boxImgProShadow from 'assets/img/products/product-box-pro-shadow.png';
import boxImgProShadow2x from 'assets/img/products/product-box-pro-shadow@2x.png';
import boxImgProShadow3x from 'assets/img/products/product-box-pro-shadow@3x.png';
import boxImgPro from 'assets/img/products/product-box-pro.png';
import boxImgPro2x from 'assets/img/products/product-box-pro@2x.png';
import boxImgPro3x from 'assets/img/products/product-box-pro@3x.png';
import boxImgShadow from 'assets/img/products/product-box-shadow.png';
import boxImgShadow2x from 'assets/img/products/product-box-shadow@2x.png';
import boxImgShadow3x from 'assets/img/products/product-box-shadow@3x.png';
import boxImg from 'assets/img/products/product-box.png';
import boxImg2x from 'assets/img/products/product-box@2x.png';
import boxImg3x from 'assets/img/products/product-box@3x.png';
import tabletImgShadow from 'assets/img/products/product-tablet-shadow.png';
import tabletImgShadow2x from 'assets/img/products/product-tablet-shadow@2x.png';
import tabletImgShadow3x from 'assets/img/products/product-tablet-shadow@3x.png';
import tabletImg from 'assets/img/products/product-tablet.png';
import tabletImg2x from 'assets/img/products/product-tablet@2x.png';
import tabletImg3x from 'assets/img/products/product-tablet@3x.png';
import Title from 'components/molecules/Title';
import PartnerProductsTable from 'components/organisms/PartnerProductsTable';
import { PATHS } from 'configs';
import {
  articleCodeBoxList,
  articleCodeBoxProList,
  articleCodeTabletList,
  ProductAPI,
  ProductTypeEnum,
} from 'features/products';
import useApiHelper from 'helpers/apiHelper';
import { normalizeKey } from 'helpers/i18n';
import useFormTemplate from 'hooks/useFormTemplate';
import {
  GetPartnerProductsParams,
  PaginatedResponseProduct,
  useGetPartnerProducts,
} from 'hooks/useGetPartnerProducts';
import { usePartnerProducts } from 'hooks/usePartnerProducts';
import useProductList from 'hooks/useProductList';
import useProductMap from 'hooks/useProductMap';
import { debounce } from 'lodash';
import {
  ApiFilterModel,
  ApiFilterOperatorEnum,
  ApiFilterTypeEnum,
} from 'models/api';
import { IBoxesSearchForm, INITIAL_STATE_BOXES_SEARCH_FORM } from 'models/box';
import { ProductInfoModel } from 'models/product';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { productSearchSchema } from 'schemas/productSchema';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';

import { usePagination } from '../../shared-components';
import ViewKoalendarContactRequestModal from '../molecules/modals/ViewKoalendarContactRequestModal';

const ProductInformationContainer = styled('div', {
  shouldForwardProp: prop =>
    prop !== 'isSelected' &&
    prop !== 'productImg' &&
    prop !== 'productShadowImg' &&
    prop !== 'productImg2x' &&
    prop !== 'productShadowImg2x' &&
    prop !== 'productImg3x' &&
    prop !== 'productShadowImg3x',
})<{
  productImg: string;
  productImg2x: string;
  productImg3x: string;
  productShadowImg: string;
  productShadowImg2x: string;
  productShadowImg3x: string;
  isSelected: boolean;
}>`
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  background-color: ${({ theme, isSelected }) =>
    isSelected
      ? theme.palette.custom.primary[100]
      : theme.palette.custom.neutral.black[8]};
  padding: 20px 40px;

  &:not(:last-child) {
    margin-right: 20px;
  }

  width: 100%;
  min-width: 282px;
  height: 92px;
  transition: background-image 500ms, background-color 500ms;
  cursor: ${({ isSelected }) => (isSelected ? 'unset' : 'pointer')};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url(${({ productImg, productShadowImg, isSelected }) =>
    isSelected ? productShadowImg : productImg});
  background-image: -webkit-image-set
    (
      url(${({ productImg2x, productShadowImg2x, isSelected }) =>
          isSelected ? productShadowImg2x : productImg2x})
        1x,
      url(${({ productImg3x, productShadowImg3x, isSelected }) =>
          isSelected ? productShadowImg3x : productImg3x})
        2x
    );

  &:hover {
    background-image: url(${({ productShadowImg }) => productShadowImg});
    background-image: -webkit-image-set
      (
        url(${({ productShadowImg2x }) => productShadowImg2x}) 1x,
        url(${({ productShadowImg3x }) => productShadowImg3x}) 2x
      );
  }
`;

const InputContainer = styled(Box)`
  position: relative;
  width: 100%;
  height: 50px;
`;

const InputIconContainer = styled('div', {
  shouldForwardProp: prop => prop !== 'displaySearchMagnifierIcon',
})<{ displaySearchMagnifierIcon: boolean }>`
  position: absolute;
  right: 12px;
  top: ${({ displaySearchMagnifierIcon }) =>
    displaySearchMagnifierIcon ? '12px' : '15px'};
`;

const ClearButton = styled('button')`
  cursor: pointer;
  border: none;
  background: none;
`;

ClearButton.defaultProps = {
  children: <BulletWrongIcon size="1rem" />,
  type: 'button',
};

const ProductContractStateTypeSelector = styled(Box, {
  shouldForwardProp: prop => prop !== 'isSelected',
})<{
  isSelected: boolean;
}>`
  padding: 4px 16px 4px 4px;
  border-radius: 50px;
  background: ${({ theme }) => theme.palette.custom.neutral.white.pure};
  border-width: 1.5px;
  border-style: solid;
  border-color: ${({ theme, isSelected }) =>
    isSelected
      ? theme.palette.custom.primary[100]
      : theme.palette.custom.neutral.white.pure};
  cursor: pointer;
`;

export enum ProductMapContractStateTypeEnum {
  IN_SHIPMENT = 'in_shipment',
  AVAILABLE = 'available',
}

export enum ProductListDisplayTypeEnum {
  FULL = 'full',
  MAP = 'map',
}

const generateFilterForCode = (code: string) => ({
  fieldName: 'article_code',
  fieldData: code,
  nextFieldSeparator: ApiFilterOperatorEnum.OR,
  filterType: ApiFilterTypeEnum.EQUAL,
});

const PartnerInChargeProducts = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation(['products', 'general']);

  const activePartner = useMyPartnersStore(activePartnerSelector);

  const {
    getProductListProductTypeSelectedCurrent,
    setProductListProductTypeSelectedCurrent,
    getProductListPaginationCurrent,
    setProductListPaginationCurrent,
    getProductListSearchCurrent,
    setProductListSearchCurrent,
    getProductListDisplayTypeSelectedCurrent,
    setProductListDisplayTypeSelectedCurrent,
  } = useProductList();

  const {
    getProductMapContractStateTypeCurrent,
    setProductMapContractStateTypeCurrent,
  } = useProductMap();

  const { params: paginationParams, setParams: setPaginationParams } =
    usePagination(getProductListPaginationCurrent());

  setProductListPaginationCurrent(paginationParams);

  const [activeSearch, setActiveSearch] = useState<string>(
    getProductListSearchCurrent()
  );

  const [
    showKoalendarContactRequestModal,
    setShowKoalendarContactRequestModal,
  ] = useState<boolean>(false);

  // Update the default search item every time the user change it
  useEffect(() => {
    setProductListSearchCurrent(activeSearch);
  }, [activeSearch, setProductListSearchCurrent]);

  const [selectedProductType, setProductTypeSelected] =
    useState<ProductTypeEnum>(getProductListProductTypeSelectedCurrent());

  // Update the default product type selected every time the user change it
  useEffect(() => {
    setProductListProductTypeSelectedCurrent(selectedProductType);
  }, [selectedProductType, setProductListProductTypeSelectedCurrent]);

  const [
    productMapContractStateTypeSelectedList,
    setProductMapContractStateTypeSelectedList,
  ] = useState<ProductMapContractStateTypeEnum[]>(
    getProductMapContractStateTypeCurrent()
  );

  const checkIfContractStateTypeIsInList = useCallback(
    (contractStateType: ProductMapContractStateTypeEnum): boolean =>
      productMapContractStateTypeSelectedList.includes(contractStateType),
    [productMapContractStateTypeSelectedList]
  );

  const addContractStateTypeToSelectedList = useCallback(
    (contractStateType: ProductMapContractStateTypeEnum) => {
      if (!checkIfContractStateTypeIsInList(contractStateType)) {
        setProductMapContractStateTypeSelectedList(
          productMapContractStateTypeSelectedList.concat([contractStateType])
        );
      }
    },
    [checkIfContractStateTypeIsInList, productMapContractStateTypeSelectedList]
  );

  const removeContractStateTypeInSelectedList = useCallback(
    (contractStateType: ProductMapContractStateTypeEnum) => {
      if (checkIfContractStateTypeIsInList(contractStateType)) {
        setProductMapContractStateTypeSelectedList(
          productMapContractStateTypeSelectedList.filter(
            (contractStateInList: ProductMapContractStateTypeEnum) =>
              contractStateInList !== contractStateType
          )
        );
      }
    },
    [checkIfContractStateTypeIsInList, productMapContractStateTypeSelectedList]
  );

  // Update the default product map contract state type selected every time the user change it
  useEffect(() => {
    setProductMapContractStateTypeCurrent(
      productMapContractStateTypeSelectedList
    );
  }, [
    productMapContractStateTypeSelectedList,
    setProductMapContractStateTypeCurrent,
  ]);

  const [productListDisplayTypeSelected, setProductListDisplayTypeSelected] =
    useState<ProductListDisplayTypeEnum>(
      getProductListDisplayTypeSelectedCurrent()
    );

  // Update the default product list display type selected every time the user change it
  useEffect(() => {
    setProductListDisplayTypeSelectedCurrent(productListDisplayTypeSelected);
  }, [
    productListDisplayTypeSelected,
    setProductListDisplayTypeSelectedCurrent,
  ]);

  const [productInfoList, setProductInfoList] = useState<ProductInfoModel[]>(
    []
  );

  const [isProductListUpdateNeeded, setIsProductListUpdateNeeded] =
    useState<boolean>(true);

  const { getFilterStringFromGroupList } = useApiHelper();

  const getProductsParamsFilterSearch =
    useCallback((): Array<ApiFilterModel> => {
      const filterList: Array<ApiFilterModel> = [];

      if (activeSearch !== '') {
        filterList.push({
          fieldName: 'lp_ui',
          // Remove globally all hyphens and whitespace caracters
          // from the search term (space, tabulation and new lines)
          fieldData: activeSearch.replace(/-|\s/g, ''),
          nextFieldSeparator: ApiFilterOperatorEnum.OR,
          filterType: ApiFilterTypeEnum.INCLUDE,
        });

        filterList.push({
          fieldName: 'holder_name',
          fieldData: activeSearch,
          nextFieldSeparator: ApiFilterOperatorEnum.OR,
          filterType: ApiFilterTypeEnum.INCLUDE,
        });

        filterList.push({
          fieldName: 'contract_state',
          fieldData: activeSearch,
          nextFieldSeparator: ApiFilterOperatorEnum.OR,
          filterType: ApiFilterTypeEnum.INCLUDE,
        });
      }

      return filterList;
    }, [activeSearch]);

  const getProductsParamsFilterListByProductType = useCallback(
    (productType: ProductTypeEnum): Array<ApiFilterModel> => {
      const filterList: Array<ApiFilterModel> = [];

      if (productType === ProductTypeEnum.BOX) {
        filterList.push(...articleCodeBoxList.map(generateFilterForCode));
      }

      if (productType === ProductTypeEnum.BOX_PRO) {
        filterList.push(...articleCodeBoxProList.map(generateFilterForCode));
      }

      if (productType === ProductTypeEnum.TABLET) {
        filterList.push(...articleCodeTabletList.map(generateFilterForCode));
      }

      return filterList;
    },
    []
  );

  const productsParams: GetPartnerProductsParams = useMemo(() => {
    const filterList = [
      {
        list: getProductsParamsFilterSearch(),
        nextGroupSeparator: ApiFilterOperatorEnum.AND,
      },
      {
        list: getProductsParamsFilterListByProductType(selectedProductType),
      },
    ];

    return {
      partnerId: activePartner.id,
      ...paginationParams,
      search:
        filterList.length !== 0
          ? getFilterStringFromGroupList(filterList)
          : undefined,
    };
  }, [
    activePartner.id,
    paginationParams,
    selectedProductType,
    getFilterStringFromGroupList,
    getProductsParamsFilterListByProductType,
    getProductsParamsFilterSearch,
  ]);

  const fetchProducts = usePartnerProducts(productsParams);

  const [displaySearchMagnifierIcon, setDisplaySearchMagnifierIcon] =
    useState<boolean>(true);

  const getPartnerProducts = useGetPartnerProducts();

  const {
    formState: { touchedFields },
    getValues,
    setValue,
    reset,
    control,
  } = useFormTemplate<IBoxesSearchForm>({
    resolver: productSearchSchema,
    defaultValues: INITIAL_STATE_BOXES_SEARCH_FORM,
  });

  const debouncedProductSearch = React.useRef(
    debounce(async (term: string) => {
      if (term === '') {
        handleClearSearch();
      } else {
        if (paginationParams.page !== 1) {
          setPaginationParams({ page: 1 });
        }

        setActiveSearch(term);
        setIsProductListUpdateNeeded(true);
      }
    }, 1300)
  ).current;

  const handleClearSearch = () => {
    if (paginationParams.page !== 1) {
      setPaginationParams({ page: 1 });
    }
    setActiveSearch('');
    setDisplaySearchMagnifierIcon(true);
    setIsProductListUpdateNeeded(true);
    reset();
  };

  const redirectToBoxDetail = useCallback(
    (lpUi: string) => {
      navigate(
        generatePath(PATHS.PRODUCT.DETAIL, {
          partnerId: activePartner.id,
          lpUi: lpUi,
        })
      );
    },
    [navigate, activePartner]
  );

  const handleInputChange = useCallback(() => {
    const formValues = getValues();
    const searchTerm = formValues.searchTerm;
    setDisplaySearchMagnifierIcon(!searchTerm);
    debouncedProductSearch(searchTerm);
  }, [debouncedProductSearch, getValues]);

  useEffect(() => {
    if (
      getProductListSearchCurrent() !== '' &&
      touchedFields.searchTerm !== true
    ) {
      setValue('searchTerm', getProductListSearchCurrent(), {
        shouldValidate: true,
        shouldDirty: true,
      });

      handleInputChange();
    }
  }, [getProductListSearchCurrent, setValue, touchedFields, handleInputChange]);

  const getProductsParams = (filter?: string) => {
    const productsParamsProduct = { ...productsParams };

    productsParamsProduct.pageSize = 0;
    productsParamsProduct.page = 0;

    if (filter) {
      productsParamsProduct.search = filter;
    }

    return productsParamsProduct;
  };

  const productsParamsBox = getProductsParams(
    getFilterStringFromGroupList([
      {
        list: getProductsParamsFilterSearch(),
        nextGroupSeparator: ApiFilterOperatorEnum.AND,
      },
      {
        list: getProductsParamsFilterListByProductType(ProductTypeEnum.BOX),
      },
    ])
  );
  const productsParamsBoxPro = getProductsParams(
    getFilterStringFromGroupList([
      {
        list: getProductsParamsFilterSearch(),
        nextGroupSeparator: ApiFilterOperatorEnum.AND,
      },
      {
        list: getProductsParamsFilterListByProductType(ProductTypeEnum.BOX_PRO),
      },
    ])
  );
  const productsParamsTablet = getProductsParams(
    getFilterStringFromGroupList([
      {
        list: getProductsParamsFilterSearch(),
        nextGroupSeparator: ApiFilterOperatorEnum.AND,
      },
      {
        list: getProductsParamsFilterListByProductType(ProductTypeEnum.TABLET),
      },
    ])
  );

  const refreshProductTypeSelectionList = useCallback(() => {
    const productInfoListNew: ProductInfoModel[] = [];

    getPartnerProducts(productsParamsBox).then(
      (productDataBox: PaginatedResponseProduct<ProductAPI>) => {
        productInfoListNew.push({
          nbProduct: productDataBox.total,
          productType: ProductTypeEnum.BOX,
          productImg: boxImg,
          productImg2x: boxImg2x,
          productImg3x: boxImg3x,
          productShadowImg: boxImgShadow,
          productShadowImg2x: boxImgShadow2x,
          productShadowImg3x: boxImgShadow3x,
        });

        getPartnerProducts(productsParamsBoxPro).then(
          (productDataBoxPro: PaginatedResponseProduct<ProductAPI>) => {
            productInfoListNew.push({
              nbProduct: productDataBoxPro.total,
              productType: ProductTypeEnum.BOX_PRO,
              productImg: boxImgPro,
              productImg2x: boxImgPro2x,
              productImg3x: boxImgPro3x,
              productShadowImg: boxImgProShadow,
              productShadowImg2x: boxImgProShadow2x,
              productShadowImg3x: boxImgProShadow3x,
            });

            getPartnerProducts(productsParamsTablet).then(
              (productDataBoxTablet: PaginatedResponseProduct<ProductAPI>) => {
                productInfoListNew.push({
                  nbProduct: productDataBoxTablet.total,
                  productType: ProductTypeEnum.TABLET,
                  productImg: tabletImg,
                  productImg2x: tabletImg2x,
                  productImg3x: tabletImg3x,
                  productShadowImg: tabletImgShadow,
                  productShadowImg2x: tabletImgShadow2x,
                  productShadowImg3x: tabletImgShadow3x,
                });

                setProductInfoList(productInfoListNew);
              }
            );
          }
        );
      }
    );
  }, [
    getPartnerProducts,
    productsParamsBox,
    productsParamsBoxPro,
    productsParamsTablet,
  ]);

  useEffect(() => {
    if (isProductListUpdateNeeded) {
      refreshProductTypeSelectionList();
      setIsProductListUpdateNeeded(false);
    }
  }, [
    refreshProductTypeSelectionList,
    isProductListUpdateNeeded,
    setIsProductListUpdateNeeded,
  ]);

  return (
    <>
      <Title pageName={t('products:list.pageName')} />
      <Stack
        direction="row"
        alignItems="flex-end"
        marginBottom="25px"
        justifyContent="flex-start"
      >
        <Stack justifyContent="flex-start" alignItems="flex-end" width="100%">
          {/* 3 cards (The BOXes, The BOXes Pro, The TABLET) */}
          {productInfoList && (
            <Stack
              direction="row"
              justifyContent="space-between"
              width="100%"
              marginBottom="24px"
            >
              {productInfoList.map((productInfo: ProductInfoModel) => (
                <ProductInformationContainer
                  productImg={productInfo.productImg}
                  productImg2x={productInfo.productImg2x}
                  productImg3x={productInfo.productImg3x}
                  productShadowImg={productInfo.productShadowImg}
                  productShadowImg2x={productInfo.productShadowImg2x}
                  productShadowImg3x={productInfo.productShadowImg2x}
                  isSelected={selectedProductType === productInfo.productType}
                  onClick={() => {
                    if (paginationParams.page !== 1) {
                      setPaginationParams({ page: 1 });
                    }

                    setProductTypeSelected(productInfo.productType);
                  }}
                  key={productInfo.productType}
                >
                  <Stack justifyContent="flex-start" marginLeft="55%">
                    <Text
                      variant="titleXL"
                      color={
                        selectedProductType === productInfo.productType
                          ? 'white'
                          : 'black.30'
                      }
                      sx={{ transition: '500ms' }}
                    >
                      {productInfo.nbProduct}
                    </Text>
                    <Text
                      variant="titleM"
                      color={
                        selectedProductType === productInfo.productType
                          ? 'white'
                          : 'black.30'
                      }
                      sx={{ transition: '500ms', whiteSpace: 'nowrap' }}
                    >
                      {t(
                        normalizeKey(
                          `products:type.${productInfo.productType}.singular`
                        )
                      )}
                    </Text>
                  </Stack>
                </ProductInformationContainer>
              ))}
            </Stack>
          )}
          {/* Filters */}
          <Stack
            direction="row"
            width="100%"
            height="3rem"
            justifyContent="space-between"
            alignItems="center"
            gap="1.25rem"
          >
            {productListDisplayTypeSelected ===
              ProductListDisplayTypeEnum.FULL && (
              <InputContainer>
                <Controller
                  name="searchTerm"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      required
                      id="box-search-input"
                      placeholder={t('products:list.inputSearch.placeholder')}
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                      fullWidth
                      sx={{
                        '& .MuiFilledInput-root': {
                          '& .MuiFilledInput-input': {
                            pb: '1.4375rem',
                          },
                        },
                      }}
                      {...field}
                      onChange={e => {
                        field.onChange(e);
                        handleInputChange();
                      }}
                    />
                  )}
                />
                <InputIconContainer
                  displaySearchMagnifierIcon={displaySearchMagnifierIcon}
                >
                  {displaySearchMagnifierIcon && (
                    <SearchIcon color={theme.palette.custom.primary[100]} />
                  )}
                  {!displaySearchMagnifierIcon && (
                    <ClearButton
                      aria-label={t('general:clearSearch')}
                      title={t('general:clearSearch')}
                      onClick={handleClearSearch}
                    />
                  )}
                </InputIconContainer>
              </InputContainer>
            )}
            {productListDisplayTypeSelected ===
              ProductListDisplayTypeEnum.MAP && (
              <Stack direction="row" alignItems="center" gap="9px">
                <ProductContractStateTypeSelector
                  isSelected={checkIfContractStateTypeIsInList(
                    ProductMapContractStateTypeEnum.IN_SHIPMENT
                  )}
                  onClick={() => {
                    if (
                      !checkIfContractStateTypeIsInList(
                        ProductMapContractStateTypeEnum.IN_SHIPMENT
                      )
                    ) {
                      addContractStateTypeToSelectedList(
                        ProductMapContractStateTypeEnum.IN_SHIPMENT
                      );
                    } else {
                      removeContractStateTypeInSelectedList(
                        ProductMapContractStateTypeEnum.IN_SHIPMENT
                      );
                    }
                  }}
                >
                  <Stack direction="row" alignItems="center" gap=".625rem">
                    <img
                      src={MarkerBoxInShipmentImg}
                      alt="Contract State Type : In Travel"
                      style={{ width: '32px', height: '32px' }}
                    />
                    <Text variant="titleM">
                      {t('products:list.mapContractStateSelector.inTravel')}
                    </Text>
                  </Stack>
                </ProductContractStateTypeSelector>
                <ProductContractStateTypeSelector
                  isSelected={checkIfContractStateTypeIsInList(
                    ProductMapContractStateTypeEnum.AVAILABLE
                  )}
                  onClick={() => {
                    if (
                      !checkIfContractStateTypeIsInList(
                        ProductMapContractStateTypeEnum.AVAILABLE
                      )
                    ) {
                      addContractStateTypeToSelectedList(
                        ProductMapContractStateTypeEnum.AVAILABLE
                      );
                    } else {
                      removeContractStateTypeInSelectedList(
                        ProductMapContractStateTypeEnum.AVAILABLE
                      );
                    }
                  }}
                >
                  <Stack direction="row" alignItems="center" gap=".625rem">
                    <img
                      src={MarkerBoxAvailableImg}
                      alt="Contract State Type : Available"
                      style={{ width: '32px', height: '32px' }}
                    />
                    <Text variant="titleM">
                      {t('products:list.mapContractStateSelector.available')}
                    </Text>
                  </Stack>
                </ProductContractStateTypeSelector>
              </Stack>
            )}
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              gap=".75rem"
            >
              <Text
                variant="titleS"
                color="custom.neutral.black.50"
                sx={{ whiteSpace: 'nowrap' }}
              >
                {productListDisplayTypeSelected ===
                ProductListDisplayTypeEnum.FULL
                  ? t('products:list.mapMode')
                  : t('products:list.listMode')}
              </Text>
              <Button
                variant="tertiary"
                icon={
                  productListDisplayTypeSelected ===
                  ProductListDisplayTypeEnum.FULL
                    ? MapIcon
                    : BulletListIcon
                }
                onClick={() => {
                  setProductListDisplayTypeSelected(
                    productListDisplayTypeSelected ===
                      ProductListDisplayTypeEnum.FULL
                      ? ProductListDisplayTypeEnum.MAP
                      : ProductListDisplayTypeEnum.FULL
                  );
                }}
                sx={{ svg: { color: theme.palette.custom.neutral.black[100] } }}
                data-testid="product-list-display-selector"
              />
            </Stack>
          </Stack>

          {/* End of Filters */}
        </Stack>
      </Stack>
      <PartnerProductsTable
        products={fetchProducts}
        pagination={paginationParams}
        onPaginationChange={setPaginationParams}
        onProductSelect={product => redirectToBoxDetail(product.lp_ui)}
        productListDisplayTypeSelected={productListDisplayTypeSelected}
        productListContractStateTypeSelectedList={
          productMapContractStateTypeSelectedList
        }
        selectedProductType={selectedProductType}
      />
      {productListDisplayTypeSelected === ProductListDisplayTypeEnum.FULL &&
        fetchProducts.state === 'success' &&
        fetchProducts.resource.total === 0 && (
          <Box>
            {activeSearch === '' ? (
              <Box paddingTop="4rem">
                <EmptyState type="Products">
                  <Text variant="titleM" color="custom.neutral.black.50">
                    {t('products:list.emptyState.emptyMessage')}
                  </Text>
                  <Text variant="bodyTextL" color="custom.neutral.black.50">
                    {t('products:list.emptyState.emptySubMessage')}
                  </Text>
                  <Button
                    sx={{ marginTop: '1.625rem' }}
                    onClick={() => setShowKoalendarContactRequestModal(true)}
                  >
                    {t('products:list.emptyState.cta')}
                  </Button>
                </EmptyState>
                <ViewKoalendarContactRequestModal
                  visible={showKoalendarContactRequestModal}
                  onDismiss={() => setShowKoalendarContactRequestModal(false)}
                ></ViewKoalendarContactRequestModal>
              </Box>
            ) : (
              <Box paddingTop="4rem">
                <EmptyState type="Results">
                  <Text variant="titleM" color="custom.neutral.black.50">
                    {t('products:list.noResult.emptyMessage')}
                  </Text>
                  <Text variant="bodyTextL" color="custom.neutral.black.50">
                    {t('products:list.noResult.emptySubMessage')}
                  </Text>
                  <Button
                    variant="primary"
                    color="primary"
                    sx={{ marginTop: '1.625rem' }}
                    onClick={() => handleClearSearch()}
                  >
                    {t('products:list.noResult.cta')}
                  </Button>
                </EmptyState>
              </Box>
            )}
          </Box>
        )}
    </>
  );
};

export default PartnerInChargeProducts;
