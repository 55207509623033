// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import useToastMessages from 'hooks/useToastMessages';
import { useTranslation } from 'react-i18next';

import { useCreateMember } from '../api/createMember';
import Layout from '../components/Layout';
import { MemberForm } from '../components/MemberForm';
import { IMemberForm } from '../types';

export const CreateMember = ({
  backToMembersList,
}: {
  backToMembersList: () => void;
}) => {
  const { t } = useTranslation(['general', 'userManagement', 'messages']);
  const { error: toastError, success: toastSuccess } = useToastMessages();

  const { mutateAsync: createMember } = useCreateMember();

  const onSubmit = async (data: IMemberForm) => {
    try {
      await createMember(data);

      toastSuccess('messages:partnerMemberCreateSuccess.message');
      backToMembersList();
    } catch (error: any) {
      let errorMessage = 'userManagement:create.errorMessages.generic';

      if (error.apiError.code === 'lpvillageAlreadyAccessPartnerForAccount') {
        errorMessage = 'userManagement:create.errorMessages.alreadyAccess';
      } else if (
        error.apiError.code === 'lpvillageMembershipInvitationImpossible'
      ) {
        errorMessage =
          'userManagement:create.errorMessages.personnalAccountType';
      }

      toastError(errorMessage);
    }
  };

  return (
    <Layout
      path={t('userManagement:create.breadcrumb')}
      subtitle={t('userManagement:create.title')}
      title={t('userManagement:create.subTitle')}
    >
      <MemberForm onSubmit={onSubmit} backToMembersList={backToMembersList} />
    </Layout>
  );
};
