// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  Grid,
  NoneIcon,
  Stack,
  Text,
} from '@livingpackets/design-system-react-next';
import BatteryInfoIcon from 'components/molecules/box/BatteryInfoIcon';
import BatteryInfoText from 'components/molecules/box/BatteryInfoText';
import LockingStateIcon from 'components/molecules/box/LockingStateIcon';
import LockingStateText from 'components/molecules/box/LockingStateText';
import { useTranslation } from 'react-i18next';

import GreyCard from './GreyCard';
import { ProductTypeEnum } from '../../../products/types';
import { ProductEntity } from '../../entities/ProductEntity';

type Props = {
  product: ProductEntity;
  productClosingStateText: string | JSX.Element;
};

const ProductInformation = ({ product, productClosingStateText }: Props) => {
  const { t } = useTranslation('products');

  return (
    <Stack gap=".75rem">
      <Text variant="titleXS" color="custom.neutral.black.50">
        {t('detail.productInformation.title')}
      </Text>
      <GreyCard>
        <Grid
          container
          direction={{ mobile: 'row', laptop: 'column' }}
          spacing="1.25rem"
        >
          {/*  Battery */}
          <Grid item mobile={6} tablet={3}>
            <Stack gap=".375rem">
              <Text variant="titleXS" color="custom.neutral.black.50">
                {t('detail.productInformation.battery')}
              </Text>
              <Stack direction="row" gap={0.5} alignItems="center">
                <BatteryInfoIcon batteryLevel={product.batteryLevel} />
                <BatteryInfoText
                  batteryLevel={Math.floor(product.batteryLevel)}
                  variant="titleXS"
                />
              </Stack>
            </Stack>
          </Grid>
          {/* Lock State */}
          {product.productType !== ProductTypeEnum.TABLET && (
            <Grid item mobile={6} tablet={3}>
              <Stack gap=".375rem">
                <Text variant="titleXS" color="custom.neutral.black.50">
                  {t('detail.productInformation.lockingState')}
                </Text>
                <Stack
                  direction="row"
                  gap={0.5}
                  alignItems="center"
                  flexWrap="wrap"
                >
                  <LockingStateIcon lockState={product.lockState} />
                  <Text variant="titleXS" data-testid="product-lock-state">
                    <LockingStateText lockState={product.lockState} />
                  </Text>
                </Stack>
              </Stack>
            </Grid>
          )}
          <Grid item mobile={6} tablet={3}>
            <Stack gap=".375rem">
              <Text variant="titleXS" color="custom.neutral.black.50">
                {t('detail.productInformation.closingState')}
              </Text>
              <Text variant="titleXS">{productClosingStateText}</Text>
            </Stack>
          </Grid>
          <Grid item mobile={6} tablet={3}>
            <Stack gap=".375rem">
              <Text variant="titleXS" color="custom.neutral.black.50">
                {t('detail.productInformation.firmwareVersion')}
              </Text>
              <Text variant="titleXS">
                {product.firmwareVersion || <NoneIcon size=".9375rem" />}
              </Text>
            </Stack>
          </Grid>
        </Grid>
      </GreyCard>
    </Stack>
  );
};

export default ProductInformation;
