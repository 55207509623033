// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useCallback } from 'react';

import PartnerContactAddressAdd from 'components/molecules/userManagement/PartnerMemberManagementAdd';
import PartnerMemberManagementEdit from 'components/molecules/userManagement/PartnerMemberManagementEdit';
import PartnerUserManagementOverview from 'components/molecules/userManagement/PartnerMemberManagementOverview';
import { PATHS } from 'configs';
import {
  generatePath,
  Navigate,
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';

import { CreateMember } from './CreateMember';
import { UpdateMember } from './UpdateMember';

// http://localhost:3000/partner/:partnerId/management

/**
 * Base URL: /partner/:partnerId/management/*
 */
export const MEMBERS_ROOT = '/management';

export const MembersRoutes = () => {
  const navigate = useNavigate();

  const activePartner = useMyPartnersStore(activePartnerSelector);

  const backToMembersList = useCallback(() => {
    navigate(
      generatePath(PATHS.USER_MANAGEMENT.LIST, {
        partnerId: activePartner.id,
      })
    );
  }, [navigate, activePartner]);

  return (
    <Routes>
      <Route
        path=""
        index
        // Later changes with <MembersList />
        element={<PartnerUserManagementOverview />}
      />
      <Route
        path="create"
        element={<CreateMember backToMembersList={backToMembersList} />}
      />
      <Route
        path=":memberId/edit"
        element={<UpdateMember backToMembersList={backToMembersList} />}
      />
      <Route path="create_old" element={<PartnerContactAddressAdd />} />
      <Route
        path=":memberId/edit_old"
        element={<PartnerMemberManagementEdit />}
      />
      <Route path="*" element={<Navigate to="." />} />
    </Routes>
  );
};
