// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  Text,
  Stack,
  ModalV2 as Modal,
} from '@livingpackets/design-system-react-next';

const ProductScreenModal = ({
  open,
  setOpen,
  productScreenUrl,
  headerLabel,
}: any) => (
  <Modal open={open} setOpen={setOpen}>
    <Stack maxWidth="45rem" gap={1.5}>
      <Text variant="titleL">{headerLabel}</Text>
      <img src={productScreenUrl} alt="Product Screen" />
    </Stack>
  </Modal>
);

export default ProductScreenModal;
