// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useCallback, useMemo } from 'react';

import {
  ArrowLeftIcon,
  ArrowRightIcon,
  DeliveryKeyIcon,
  Divider,
  MinusIcon,
  NewTooltip,
  Stack,
  Text as TextV2,
  EmptyDeliveryKeyIcon,
  ShipmentArchiveIcon,
  DeleteIcon,
  Box,
  Text,
  styled,
  useTheme,
} from '@livingpackets/design-system-react-next';
import DateTagSkeleton from 'components/atoms/loadingSkeletons/DateTagSkeleton';
import { TableDesignTypeEnum } from 'components/atoms/TableComponents';
import { Address, AddressName } from 'components/molecules/Address';
import ReactTableTable, { TColumn } from 'components/molecules/ReactTableTable';
import {
  TooltipRemoteControl,
  TooltipReturnProduct,
} from 'features/remoteControl';
import isCancelledState from 'helpers/isCancelledState';
import { PaginatedResponse } from 'hooks/useGetPartnerShipments';
import useIntlFormatTimestamp from 'hooks/useIntlFormatTimestamp';
import { ShipmentListNavTabEnum } from 'hooks/useShipmentTab';
import { IShipment, ShipmentContractStateEnum } from 'models/shipment';
import { useTranslation } from 'react-i18next';
import { Row } from 'react-table';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';

import useShipmentList from '../../hooks/useShipmentList';
import { PaginationParams, RequestState } from '../../shared-components';

export type ShipmentDirection = 'original' | 'return';
export type ShipmentContractState = 'purchased' | 'deployed' | 'arrived';
export type IType =
  | 'draft'
  | 'label'
  | 'outgoing'
  | 'return'
  | 'completed'
  | 'archived';

const canBeArchived = (shipment: IShipment): boolean =>
  shipment.contractState === ShipmentContractStateEnum.ARRIVED &&
  !shipment.isArchived;

const canBeDeleted = (shipment: IShipment): boolean =>
  shipment.contractState === ShipmentContractStateEnum.DRAFT;

interface IPartnerShipmentsTable {
  shipments: RequestState<PaginatedResponse<IShipment>>;
  activeShipment?: IShipment;
  pagination: PaginationParams;
  onPaginationChange: (params: Partial<PaginationParams>) => void;
  onShipmentDelete: (shipment: IShipment) => void;
  onShipmentSelect: (shipment: IShipment) => void;
  onRemoteSelect: (shipment: IShipment) => void;
  onDeployReturnSelect: (shipment: IShipment) => void;
  onShipmentArchive: (shipment: IShipment) => void;
  currentContractState: ShipmentContractStateEnum;
  currentNavTab?: ShipmentListNavTabEnum;
}

const IconWrap = styled('div')`
  cursor: pointer;
  opacity: 0;
  transition: opacity 300ms;
  margin-left: 1rem;

  tr:hover & {
    opacity: 1;
  }
`;

const ArchiveIcon = styled(ShipmentArchiveIcon)`
  cursor: pointer;
  :hover {
    color: ${({ theme }) => theme.palette.custom.primary[100]};
  }
`;

const CustomDeliveryKeyIcon = styled(DeliveryKeyIcon)`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.custom.primary[100]};
  width: 1rem;
  height: 1rem;
`;

const CustomEmptyDeliveryKeyIcon = styled(EmptyDeliveryKeyIcon)`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.custom.neutral.black[50]};
  width: 1rem;
  height: 1rem;
`;

const ShipmentCreationDateText = styled(Text, {
  shouldForwardProp: prop => prop !== 'isHovered',
})<{ isHovered: boolean }>`
  white-space: nowrap;
  margin-left: 16px;
  padding: 8px;
  background-color: ${({ isHovered, theme }) =>
    isHovered ? theme.palette.custom.primary[15] : 'transparent'};
  border-radius: ${({ theme }) => theme.borderRadius.xs};
  transition: all 500ms ease-out;
`;

const ShipmentArrowDirectionContainer = styled('div', {
  shouldForwardProp: prop => prop !== 'isShipmentReturn',
})<{
  isShipmentReturn: boolean;
}>`
  width: 30px;
  margin-left: auto;
  margin-right: auto;

  height: ${({ isShipmentReturn }) => (isShipmentReturn ? '76px' : '46px')};
  line-height: ${({ isShipmentReturn }) =>
    isShipmentReturn ? '84px' : '64px'};
`;

const ArrowRightIconContainer = styled('div')`
  width: 14px;
  height: 9px;
`;

const ArrowLeftIconContainer = styled('div')`
  width: 14px;
  height: 9px;
  margin-top: 6px;
  margin-left: -7px;
`;

const CreatedAtDivider = styled('div')<{ isShipmentReturnShipment: boolean }>`
  height: ${({ isShipmentReturnShipment }) =>
    isShipmentReturnShipment ? '76px' : '32px'};
  width: 1px;
  background-color: ${({ theme }) => theme.palette.custom.neutral.black[10]};
`;

const GreenDot = styled('div')`
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.custom.primary[100]};
  position: absolute;
  left: -10px;
  top: 3px;
`;

const CancelledTooltip = ({ show, children }: any) => {
  const { t } = useTranslation('shipments');

  if (!show) return <>{children}</>;

  return (
    <NewTooltip
      placement="bottom-start"
      title={
        <Stack gap="0.5rem" px=".75rem">
          <Text variant="titleM">{t('cancelledTooltip.title')}</Text>
          <Text variant="bodyTextS">{t('cancelledTooltip.description')}</Text>
        </Stack>
      }
      sx={{ minWidth: '11.6875rem' }}
    >
      {children}
    </NewTooltip>
  );
};

const PartnerShipmentsTable = ({
  shipments,
  activeShipment,
  pagination,
  onPaginationChange,
  onShipmentSelect,
  currentContractState,
  onShipmentArchive,
  onShipmentDelete,
  onRemoteSelect,
  onDeployReturnSelect,
  currentNavTab,
}: IPartnerShipmentsTable) => {
  const theme = useTheme();
  const { t } = useTranslation(['shipments', 'general']);

  const activePartner = useMyPartnersStore(activePartnerSelector);

  const formatTimestamp = useIntlFormatTimestamp();
  const {
    isShipmentOutgoing,
    isShipmentOutgoingAndReturn,
    isShipmentReturn,
    isShipmentDeployed,
    isShipmentDeliveryKeyShared,
  } = useShipmentList();

  const isGreenDotDisplayed = useCallback(
    (
      shipmentContractState: string,
      relatedShipmentContractSate: string | undefined
    ): boolean =>
      currentContractState ===
        (shipmentContractState as ShipmentContractStateEnum) &&
      shipmentContractState !== relatedShipmentContractSate,
    [currentContractState]
  );

  const isShipmentContractStateEqualCurrentTab = useCallback(
    (shipmentContractState: string): boolean =>
      currentContractState === shipmentContractState,
    [currentContractState]
  );

  const customCancelStateStyle = useMemo(
    () => ({
      style: {
        textDecoration: 'line-through',
        color: theme.palette.custom.neutral.black[50],
        fontWeight: 400,
      },
    }),
    [theme]
  );

  const getDateLabel = () => {
    if (currentContractState === ShipmentContractStateEnum.DEPLOYED) {
      return (
        <Text variant="titleXS" color="custom.neutral.black.50">
          {t('shipments:list.header.deployedDate')}
        </Text>
      );
    } else if (currentContractState === ShipmentContractStateEnum.ARRIVED) {
      return (
        <Text variant="titleXS" color="custom.neutral.black.50">
          {t('shipments:list.header.receivedDate')}
        </Text>
      );
    } else {
      return (
        <Text variant="titleXS" color="custom.neutral.black.50">
          {t('shipments:list.header.creationDate')}
        </Text>
      );
    }
  };

  const getDateKey = () => {
    if (currentContractState === ShipmentContractStateEnum.DEPLOYED) {
      return 'deployedAt';
    } else if (currentContractState === ShipmentContractStateEnum.ARRIVED) {
      return 'receivedAt';
    } else {
      return 'createdAt';
    }
  };

  const columns: TColumn<IShipment>[] = [
    {
      Header: getDateLabel(),
      accessor: getDateKey(),
      headerEmphasis: true,
      // @ts-ignore
      Cell: ({
        row,
        isHovered,
      }: {
        row: Row<IShipment>;
        isHovered: boolean;
      }) => {
        const timestampdateDisplay = row.original[getDateKey()];

        return (
          <Stack direction="row">
            <ShipmentCreationDateText isHovered={isHovered} variant="titleXS">
              {formatTimestamp(timestampdateDisplay!, {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
                hour: undefined,
                minute: undefined,
              })}
            </ShipmentCreationDateText>
          </Stack>
        );
      },
      sortable: true,
      skeleton: (
        <Stack direction="row">
          <DateTagSkeleton />
        </Stack>
      ),
      width: '150px',
      dataTestId: 'partnerShipmentList-header-creationDate',
    },
    {
      Header: '',
      id: 'createdAtDivider',
      Cell: ({ row }: { row: Row<IShipment> }) => (
        <>
          <CreatedAtDivider
            isShipmentReturnShipment={!!row.original.returnShipmentId}
          />
        </>
      ),
      width: '1px',
    },
    {
      Header: '',
      id: 'direction',
      Cell: ({ row }: { row: Row<IShipment> }) => (
        <>
          {isShipmentOutgoing(row.original) ? (
            <ShipmentArrowDirectionContainer isShipmentReturn={false}>
              <ArrowRightIcon color={theme.palette.custom.primary[100]} />
            </ShipmentArrowDirectionContainer>
          ) : (
            <ShipmentArrowDirectionContainer isShipmentReturn={true}>
              <ArrowRightIconContainer>
                <ArrowRightIcon color={theme.palette.custom.primary[100]} />
              </ArrowRightIconContainer>
              <ArrowLeftIconContainer>
                {isCancelledState(row?.original?.returnShipment) ? (
                  <MinusIcon color={theme.palette.custom.neutral.black[20]} />
                ) : (
                  <ArrowLeftIcon color={theme.palette.custom.primary[100]} />
                )}
              </ArrowLeftIconContainer>
            </ShipmentArrowDirectionContainer>
          )}
        </>
      ),
      width: '60px',
    },
    {
      Header: t('shipments:list.header.shipmentName'),
      accessor: 'reference',
      Cell: ({ row }: { row: Row<IShipment> }) => (
        <Stack>
          {isShipmentOutgoing(row.original) && (
            <Text variant="titleXS">{row.original.reference}</Text>
          )}
          {isShipmentOutgoingAndReturn(row.original) && (
            <>
              <Text
                variant="titleXS"
                color={
                  isShipmentContractStateEqualCurrentTab(
                    row.original.contractState!
                  )
                    ? 'custom.neutral.black.100'
                    : 'custom.neutral.black.50'
                }
              >
                {row.original.reference}
              </Text>
              {row.original.returnShipment && (
                <CancelledTooltip
                  show={isCancelledState(row.original.returnShipment)}
                >
                  <Text
                    variant="titleXS"
                    marginTop="1.5rem"
                    color={
                      isShipmentContractStateEqualCurrentTab(
                        row.original.returnShipment.contractState!
                      )
                        ? 'custom.neutral.black.100'
                        : 'custom.neutral.black.50'
                    }
                    {...(isCancelledState(row.original.returnShipment)
                      ? customCancelStateStyle
                      : {})}
                  >
                    {row.original.returnShipment.reference}
                  </Text>
                </CancelledTooltip>
              )}
            </>
          )}
          {isShipmentReturn(row.original) && (
            <>
              {row.original.originalShipment && (
                <Text
                  variant="titleXS"
                  color={
                    isShipmentContractStateEqualCurrentTab(
                      row.original.originalShipment.contractState!
                    )
                      ? 'custom.neutral.black.100'
                      : 'custom.neutral.black.50'
                  }
                >
                  {row.original.originalShipment.reference}
                </Text>
              )}
              <Text
                variant="titleXS"
                marginTop={row.original.originalShipment ? '24px' : 0}
                color={
                  isShipmentContractStateEqualCurrentTab(
                    row.original.contractState!
                  )
                    ? 'custom.neutral.black.100'
                    : 'custom.neutral.black.50'
                }
              >
                {row.original.reference}
              </Text>
            </>
          )}
        </Stack>
      ),
    },
    {
      Header: t('shipments:list.header.carrierTrackingCode'),
      accessor: 'carrier_tracking_code',
      Cell: ({ row }: { row: Row<IShipment> }) => (
        <Stack>
          {isShipmentOutgoing(row.original) && (
            <Text variant="titleXS">{row.original.carrierTrackingCode}</Text>
          )}
          {isShipmentOutgoingAndReturn(row.original) && (
            <>
              <Box position="relative">
                {isGreenDotDisplayed(
                  row.original.contractState!,
                  row.original.returnShipment?.contractState!
                ) && <GreenDot />}
                <Text
                  variant="titleXS"
                  color={
                    isShipmentContractStateEqualCurrentTab(
                      row.original.contractState!
                    )
                      ? 'custom.neutral.black.100'
                      : 'custom.neutral.black.50'
                  }
                >
                  {row.original.carrierTrackingCode}
                </Text>
              </Box>
              {row.original.returnShipment && (
                <CancelledTooltip
                  show={isCancelledState(row.original.returnShipment)}
                >
                  <Box position="relative">
                    {isGreenDotDisplayed(
                      row.original.returnShipment.contractState!,
                      row.original.contractState!
                    ) && <GreenDot />}
                    <Text
                      variant="titleXS"
                      marginTop="1.5rem"
                      color={
                        isShipmentContractStateEqualCurrentTab(
                          row.original.returnShipment.contractState!
                        )
                          ? 'custom.neutral.black.100'
                          : 'custom.neutral.black.50'
                      }
                      {...(isCancelledState(row.original.returnShipment)
                        ? customCancelStateStyle
                        : {})}
                    >
                      {row.original.returnShipment.carrierTrackingCode}
                    </Text>
                  </Box>
                </CancelledTooltip>
              )}
            </>
          )}
          {isShipmentReturn(row.original) && (
            <>
              {row.original.originalShipment && (
                <Box position="relative">
                  {isGreenDotDisplayed(
                    row.original.originalShipment?.contractState!,
                    row.original.contractState!
                  ) && <GreenDot />}
                  <Text
                    variant="titleXS"
                    color={
                      isShipmentContractStateEqualCurrentTab(
                        row.original.originalShipment.contractState!
                      )
                        ? 'custom.neutral.black.100'
                        : 'custom.neutral.black.50'
                    }
                  >
                    {row.original.originalShipment.carrierTrackingCode}
                  </Text>
                </Box>
              )}
              <Box position="relative">
                {isGreenDotDisplayed(
                  row.original.contractState!,
                  row.original.originalShipment?.contractState
                ) && <GreenDot style={{ marginTop: '24px' }} />}
                <Text
                  variant="titleXS"
                  marginTop={row.original.originalShipment ? '24px' : 0}
                  color={
                    isShipmentContractStateEqualCurrentTab(
                      row.original.contractState!
                    )
                      ? 'custom.neutral.black.100'
                      : 'custom.neutral.black.50'
                  }
                >
                  {row.original.carrierTrackingCode}
                </Text>
              </Box>
            </>
          )}
        </Stack>
      ),
    },
    {
      Header: t('shipments:list.header.recipient'),
      id: 'recipientName',
      Cell: ({ row }: { row: Row<IShipment> }) => (
        <Stack>
          {isShipmentOutgoing(row.original) && (
            <AddressName
              address={row.original.recipient.address}
              inline={false}
            />
          )}
          {isShipmentOutgoingAndReturn(row.original) && (
            <>
              <Text
                color={
                  isShipmentContractStateEqualCurrentTab(
                    row.original.contractState!
                  )
                    ? 'custom.neutral.black.100'
                    : 'custom.neutral.black.50'
                }
              >
                <AddressName
                  address={row.original.recipient.address}
                  inline={false}
                />
              </Text>
              {row.original.returnShipment && (
                <CancelledTooltip
                  show={isCancelledState(row.original.returnShipment)}
                >
                  <Text
                    variant="bodyTextS"
                    marginTop="24px"
                    color={
                      isShipmentContractStateEqualCurrentTab(
                        row.original.returnShipment.contractState!
                      )
                        ? 'custom.neutral.black.100'
                        : 'custom.neutral.black.50'
                    }
                    {...(isCancelledState(row.original.returnShipment)
                      ? customCancelStateStyle
                      : {})}
                  >
                    <AddressName
                      address={row.original.returnShipment.recipient.address}
                      inline={false}
                      style={
                        isCancelledState(row.original.returnShipment)
                          ? customCancelStateStyle.style
                          : {}
                      }
                    />
                  </Text>
                </CancelledTooltip>
              )}
            </>
          )}

          {isShipmentReturn(row.original) && (
            <>
              {row.original.originalShipment && (
                <Text
                  variant="bodyTextS"
                  color={
                    isShipmentContractStateEqualCurrentTab(
                      row.original.originalShipment.contractState!
                    )
                      ? 'custom.neutral.black.100'
                      : 'custom.neutral.black.50'
                  }
                >
                  <AddressName
                    address={row.original.originalShipment.recipient.address}
                    inline={false}
                  />
                </Text>
              )}
              <Box
                marginTop={
                  row.original.originalShipment ? '24px !important' : 0
                }
                color={
                  isShipmentContractStateEqualCurrentTab(
                    row.original.contractState!
                  )
                    ? 'black.100'
                    : 'black.50'
                }
              >
                <AddressName
                  address={row.original.recipient.address}
                  inline={false}
                />
              </Box>
            </>
          )}
        </Stack>
      ),
    },
    {
      Header: t('shipments:list.header.recipientAddress'),
      id: 'recipientAddress',
      Cell: ({ row }: { row: Row<IShipment> }) => (
        <Stack>
          {isShipmentOutgoing(row.original) && (
            <Text variant="titleXS">
              <Address
                address={row.original.recipient.address}
                inline={false}
                countryInline={false}
              />
            </Text>
          )}

          {isShipmentOutgoingAndReturn(row.original) && (
            <>
              <Text
                variant="titleXS"
                color={
                  isShipmentContractStateEqualCurrentTab(
                    row.original.contractState!
                  )
                    ? 'custom.neutral.black.100'
                    : 'custom.neutral.black.50'
                }
              >
                <Address
                  countryInline
                  inline={false}
                  address={row.original.recipient.address}
                />
              </Text>
              {row.original.returnShipment && (
                <CancelledTooltip
                  show={isCancelledState(row.original.returnShipment)}
                >
                  <Text
                    variant="titleXS"
                    mt="0.625rem !important"
                    color={
                      isShipmentContractStateEqualCurrentTab(
                        row.original.returnShipment.contractState!
                      )
                        ? 'custom.neutral.black.100'
                        : 'custom.neutral.black.50'
                    }
                  >
                    <Address
                      address={row.original.returnShipment.recipient.address}
                      countryInline={true}
                      style={
                        isCancelledState(row.original.returnShipment)
                          ? customCancelStateStyle.style
                          : {}
                      }
                    />
                  </Text>
                </CancelledTooltip>
              )}
            </>
          )}

          {isShipmentReturn(row.original) && (
            <>
              {row.original.originalShipment && (
                <Text
                  variant="titleXS"
                  color={
                    isShipmentContractStateEqualCurrentTab(
                      row.original.originalShipment.contractState!
                    )
                      ? 'custom.neutral.black.100'
                      : 'custom.neutral.black.50'
                  }
                >
                  <Address
                    countryInline
                    address={row.original.originalShipment.recipient.address}
                  />
                </Text>
              )}
              <Text
                variant="titleXS"
                marginTop={
                  row.original.originalShipment ? '10px !important' : 0
                }
                color={
                  isShipmentContractStateEqualCurrentTab(
                    row.original.contractState!
                  )
                    ? 'black.100'
                    : 'black.50'
                }
              >
                <Address
                  address={row.original.recipient.address}
                  countryInline={true}
                />
              </Text>
            </>
          )}
        </Stack>
      ),
    },

    {
      Header:
        currentNavTab === ShipmentListNavTabEnum.ongoing
          ? t('shipments:list.header.deliveryKey')
          : '',
      id: 'deliverykey',
      hasClickable: true,
      Cell: ({ row }: { row: Row<IShipment> }) => (
        <>
          <Stack
            sx={{
              alignItems: 'center',
              flexDirection: 'row',
              marginBottom: `${
                isShipmentOutgoingAndReturn(row.original) ? '2.9375rem' : 0
              }`,
              justifyContent: `${
                isShipmentDeployed(row.original) &&
                !activePartner.remoteControlEnabled
                  ? 'space-around'
                  : isShipmentDeployed(row.original)
                  ? 'center'
                  : 'end'
              }`,
            }}
          >
            {/* SHARE DELIVERY KEY  */}
            {isShipmentDeployed(row.original) && (
              <>
                <NewTooltip
                  placement="bottom-start"
                  title={
                    <Stack gap="0.5rem" px=".75rem">
                      <TextV2 variant="titleM">
                        {isShipmentDeliveryKeyShared(row.original)
                          ? t('shipments:tooltipDeliveryKey.shared')
                          : t('shipments:tooltipDeliveryKey.unshared')}
                      </TextV2>
                      <TextV2 variant="bodyTextS">
                        {isShipmentDeliveryKeyShared(row.original)
                          ? t('shipments:tooltipDeliveryKey.sharedDetail')
                          : t('shipments:tooltipDeliveryKey.unsharedDetail')}
                      </TextV2>
                    </Stack>
                  }
                >
                  <Stack
                    sx={{
                      width: '2.25rem',
                      height: '1.75rem',
                      borderRadius: '6.25rem',
                      px: '.625rem',
                      py: '.25rem',
                      backgroundColor: `${
                        isShipmentDeliveryKeyShared(row.original)
                          ? 'custom.primary.10'
                          : 'custom.neutral.black.4'
                      }`,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {isShipmentDeliveryKeyShared(row.original) ? (
                      <CustomDeliveryKeyIcon />
                    ) : (
                      <CustomEmptyDeliveryKeyIcon />
                    )}
                  </Stack>
                </NewTooltip>
              </>
            )}

            <Stack direction="row" gap=".375rem">
              {/* DEPLOY RETURN ICON */}
              {isShipmentOutgoingAndReturn(row.original) &&
                row.original.contractState === 'ARRIVED' &&
                (row.original?.returnShipment?.contractState === 'PURCHASED' ||
                  row.original?.returnShipment?.contractState ===
                    'TRANSFERRED') && (
                  <TooltipReturnProduct
                    iconOnly
                    onClick={() => onDeployReturnSelect(row.original)}
                  />
                )}

              {/* ARCHIVE ICON */}
              {canBeArchived(row.original) && (
                <ArchiveIcon onClick={() => onShipmentArchive(row.original)} />
              )}

              {/* DELETE ICON */}
              {canBeDeleted(row.original) && (
                <IconWrap onClick={() => onShipmentDelete(row.original)}>
                  <DeleteIcon />
                </IconWrap>
              )}

              {/* REMOTE CONTROL ICON */}
              {row.original.contractState === 'DEPLOYED' &&
                activePartner.remoteControlEnabled && (
                  <>
                    <Divider
                      orientation="vertical"
                      sx={{
                        height: '1.75rem',
                        marginLeft: '1.4194rem',
                        marginRight: '1.4194rem',
                      }}
                    />
                    <TooltipRemoteControl
                      onClick={() => onRemoteSelect(row.original)}
                      iconOnly
                      shipmentId={row.original.shipment_id}
                    />
                  </>
                )}
            </Stack>
          </Stack>
        </>
      ),
    },
  ];
  const handlePaginationChange = useCallback(
    ({ pageSize, offset }: { offset: number; pageSize: number }): void =>
      onPaginationChange({ page: 1 + offset / pageSize, pageSize }),
    [onPaginationChange]
  );

  const handleSortingChange = useCallback(
    ({ order, sortBy }: { sortBy: string; order: 'ASC' | 'DESC' }): void =>
      onPaginationChange({ orderColumn: sortBy, orderDirection: order }),
    [onPaginationChange]
  );

  return (
    <>
      <div style={{ position: 'relative' }}>
        <ReactTableTable<IShipment>
          onRowClick={onShipmentSelect}
          columns={columns}
          activeRowId={activeShipment?.id}
          data={
            (shipments.state === 'success' && shipments.resource.items) || []
          }
          pagination={{
            pageSize: pagination.pageSize,
            offset: (pagination.page - 1) * pagination.pageSize,
            total:
              (shipments.state === 'success' && shipments.resource.total) || 0,
          }}
          sorting={{
            sortBy: pagination.orderColumn,
            order: pagination.orderDirection,
          }}
          onPaginationChange={handlePaginationChange}
          onSortingChange={handleSortingChange}
          loading={shipments.state !== 'success' && shipments.state !== 'error'}
          tableDesignType={TableDesignTypeEnum.shipmentListTable}
          hiddenColumns={
            !activePartner.secursusEnabled ? ['figural_insurance'] : []
          }
        />
      </div>
    </>
  );
};

export default PartnerShipmentsTable;
