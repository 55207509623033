// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useCallback, useMemo } from 'react';

import {
  BatteryFullIcon,
  BatteryLowIcon,
  BatteryMiddleIcon,
  BatteryUndefinedIcon,
  LockOffIcon,
  LockOnIcon,
  Box,
  Text,
  styled,
  useTheme,
  Stack,
} from '@livingpackets/design-system-react-next';
import { TableDesignTypeEnum } from 'components/atoms/TableComponents';
import ProductListMap from 'components/molecules/product/ProductListMap';
import ReactTableTable, { TColumn } from 'components/molecules/ReactTableTable';
import {
  ProductMapContractStateTypeEnum,
  ProductListDisplayTypeEnum,
} from 'components/organisms/PartnerInChargeProducts';
import { ProductAPI, ProductTypeEnum } from 'features/products';
import { PaginatedResponse } from 'hooks/useGetPartnerShipments';
import useIntlDistanceTimestamp from 'hooks/useIntlDistanceTimestamp';
import useIntlFormatTimestamp from 'hooks/useIntlFormatTimestamp';
import { ShipmentContractStateEnum } from 'models/shipment';
import { useTranslation } from 'react-i18next';
import { Row } from 'react-table';
import { formatLpui } from 'utils/product';

import { PaginationParams, RequestState } from '../../shared-components';

interface IPartnerProductsTable {
  products: RequestState<PaginatedResponse<ProductAPI>>;
  activeBox?: ProductAPI;
  pagination: PaginationParams;
  onPaginationChange: (params: Partial<PaginationParams>) => void;
  onProductSelect: (product: ProductAPI) => void;
  productListDisplayTypeSelected: ProductListDisplayTypeEnum;
  productListContractStateTypeSelectedList: ProductMapContractStateTypeEnum[];
  selectedProductType: ProductTypeEnum;
}

const CellDivider = styled('div')`
  height: 52px;
  width: 1px;
  background-color: ${({ theme }) => theme.palette.custom.neutral.black[10]};
`;

const ContractStateInfoContainer = styled(Box, {
  shouldForwardProp: prop => prop !== 'isInShipment',
})<{ isInShipment: boolean }>`
  height: 28px;
  width: 103px;
  margin-top: 8px;
  border-radius: ${({ theme }) => theme.borderRadius.xs};
  background: ${({ theme, isInShipment }) =>
    isInShipment
      ? theme.palette.custom.primary[10]
      : theme.palette.custom.neutral.black[3]};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PartnerProductsTable = ({
  products,
  activeBox,
  pagination,
  onPaginationChange,
  onProductSelect,
  productListDisplayTypeSelected,
  productListContractStateTypeSelectedList,
  selectedProductType,
}: IPartnerProductsTable) => {
  const { t } = useTranslation('products');
  const theme = useTheme();

  const intlFormatTimestamp = useIntlFormatTimestamp();
  const intlDistanceTimestamp = useIntlDistanceTimestamp();

  const getBatteryLevelIcon = useCallback(
    (batteryLevel: number | undefined) => {
      if (!batteryLevel) {
        return <BatteryUndefinedIcon />;
      } else if (batteryLevel <= 33) {
        return <BatteryLowIcon color={theme.palette.custom.error.red[100]} />;
      } else if (batteryLevel <= 66) {
        return (
          <BatteryMiddleIcon color={theme.palette.custom.neutral.black[100]} />
        );
      } else if (batteryLevel > 66) {
        return <BatteryFullIcon color={theme.palette.custom.primary[100]} />;
      }
    },
    [theme]
  );

  const getBatteryLevelText = useCallback(
    (batteryLevel: number | undefined) => {
      if (!batteryLevel) {
        return;
      } else {
        const batteryLevelText = t('list.cell.batteryLevel', {
          batteryLevel: Math.floor(batteryLevel),
        });

        if (batteryLevel <= 33) {
          return (
            <Text variant="bodyTextS" color="custom.error.red.100">
              {batteryLevelText}
            </Text>
          );
        } else if (batteryLevel <= 66) {
          return (
            <Text variant="bodyTextS" color="custom.neutral.black.100">
              {batteryLevelText}
            </Text>
          );
        } else if (batteryLevel > 66) {
          return (
            <Text variant="bodyTextS" color="custom.primary.100">
              {batteryLevelText}
            </Text>
          );
        }
      }
    },
    [t]
  );

  const columnsFullList: TColumn<ProductAPI>[] = useMemo(
    () => [
      {
        Header: t('list.header.serialNumber'),
        accessor: 'lp_ui',
        width: '150px',
        headerEmphasis: true,
        sortable: false,
        align: 'center',
        Cell: ({ row }: { row: Row<ProductAPI> }) => (
          <Stack alignItems="flex-start" marginLeft="12px">
            <Text variant="titleXS" color="custom.neutral.black.100">
              {formatLpui(row.original.lp_ui)}
            </Text>
            {row.original.alias && (
              <Text
                variant="titleXS"
                color="custom.neutral.black.50"
                marginTop="10px"
              >
                {t('list.cell.thingName', {
                  thingName: row.original.alias,
                })}
              </Text>
            )}
          </Stack>
        ),
      },
      {
        Header: '',
        id: 'boxSerialNumberDivider',
        Cell: () => <CellDivider />,
        width: '1px',
      },
      {
        Header: t('list.header.holderName'),
        accessor: 'holder_name',
        width: '100px',
        headerEmphasis: true,
        sortable: true,
        Cell: ({ row }: { row: Row<ProductAPI> }) => (
          <Text variant="titleXS">{row.original.holder_name}</Text>
        ),
      },
      {
        Header: t('list.header.partnershipAssignment'),
        accessor: 'partner_since',
        width: '130px',
        headerEmphasis: true,
        sortable: true,
        Cell: ({ row }: { row: Row<ProductAPI> }) => (
          <Stack>
            <Text variant="titleXS">
              {t('list.cell.partnershipAssignmentDate', {
                partnershipAssignmentDate: intlFormatTimestamp(
                  row.original.partner_since
                ),
              })}
            </Text>
            {row.original.shipments_operated !== 0 && (
              <Text variant="bodyTextS" mt="10px">
                {row.original.shipments_operated === 1 &&
                  t('list.cell.partnershipNumberOfShipments.one')}
                {row.original.shipments_operated > 1 &&
                  t('list.cell.partnershipNumberOfShipments.moreThanOne', {
                    partnershipNumberOfShipments:
                      row.original.shipments_operated,
                  })}
              </Text>
            )}
          </Stack>
        ),
      },
      {
        Header: t('list.header.firmware'),
        accessor: 'firmware_version',
        width: '60px',
        headerEmphasis: true,
        sortable: true,
        align: 'center',
        Cell: ({ row }: { row: Row<ProductAPI> }) => (
          <Text variant="titleXS">{row.original.firmware_version}</Text>
        ),
      },
      {
        Header: t('list.header.battery'),
        accessor: 'battery_level',
        width: '70px',
        headerEmphasis: true,
        sortable: false,
        align: 'center',
        Cell: ({ row }: { row: Row<ProductAPI> }) => (
          <Stack direction="row" alignItems="center">
            {getBatteryLevelIcon(row.original.battery_level)}
            {getBatteryLevelText(row.original.battery_level)}
          </Stack>
        ),
      },
      {
        Header: t('list.header.lockState'),
        accessor: 'lock_state',
        width: '75px',
        headerEmphasis: true,
        sortable: false,
        align: 'center',
        Cell: ({ row }: { row: Row<ProductAPI> }) => (
          <>{row.original.lock_state ? <LockOnIcon /> : <LockOffIcon />}</>
        ),
      },
      {
        Header: t('list.header.shipmentState'),
        accessor: 'contract_state',
        width: '90px',
        headerEmphasis: true,
        sortable: false,
        align: 'center',
        Cell: ({ row }: { row: Row<ProductAPI> }) => (
          <Stack alignItems="center" marginBottom="5px">
            <ContractStateInfoContainer
              isInShipment={
                row.original.contract_state ===
                ShipmentContractStateEnum.DEPLOYED
              }
            >
              <Text
                variant="bodyTextXS"
                color={
                  row.original.contract_state ===
                  ShipmentContractStateEnum.DEPLOYED
                    ? 'custom.primary.100'
                    : 'custom.neutral.black.50'
                }
              >
                {row.original.contract_state ===
                ShipmentContractStateEnum.DEPLOYED
                  ? t('list.cell.contractState.deployed')
                  : t('list.cell.contractState.idle')}
              </Text>
            </ContractStateInfoContainer>
            <Text
              variant="bodyTextXS"
              color="custom.neutral.black.100"
              mt="10px"
            >
              {t('list.cell.lastContractStateChange', {
                lastContractStateChange: intlDistanceTimestamp(
                  row.original.contract_state_updated_at
                ),
              })}
            </Text>
          </Stack>
        ),
      },
    ],
    [
      t,
      intlFormatTimestamp,
      getBatteryLevelIcon,
      getBatteryLevelText,
      intlDistanceTimestamp,
    ]
  );

  const handlePaginationChange = useCallback(
    ({ pageSize, offset }: { offset: number; pageSize: number }): void =>
      onPaginationChange({ page: 1 + offset / pageSize, pageSize }),
    [onPaginationChange]
  );

  const handleSortingChange = useCallback(
    ({ order, sortBy }: { sortBy: string; order: 'ASC' | 'DESC' }): void =>
      onPaginationChange({ orderColumn: sortBy, orderDirection: order }),
    [onPaginationChange]
  );

  if (productListDisplayTypeSelected === ProductListDisplayTypeEnum.MAP) {
    return (
      <Stack
        direction="row"
        justifyContent="center"
        sx={{
          border: '1px solid',
          borderColor: 'custom.neutral.black.10',
          borderRadius: '1.25rem',
        }}
      >
        <ProductListMap
          productListContractStateTypeSelectedList={
            productListContractStateTypeSelectedList
          }
          selectedProductType={selectedProductType}
        />
      </Stack>
    );
  }

  return (
    <Stack direction="row" justifyContent="center">
      <Box>
        <ReactTableTable<ProductAPI>
          dataTestId="products-table"
          onRowClick={onProductSelect}
          columns={columnsFullList}
          activeRowId={activeBox?.product_id}
          data={(products.state === 'success' && products.resource.items) || []}
          pagination={{
            pageSize: pagination.pageSize,
            offset: (pagination.page - 1) * pagination.pageSize,
            total:
              (products.state === 'success' && products.resource.total) || 0,
          }}
          sorting={{
            sortBy: pagination.orderColumn,
            order: pagination.orderDirection,
          }}
          onPaginationChange={handlePaginationChange}
          onSortingChange={handleSortingChange}
          loading={products.state !== 'success'}
          tableDesignType={TableDesignTypeEnum.productListTable}
          hiddenColumns={
            selectedProductType === ProductTypeEnum.TABLET ? ['lock_state'] : []
          }
        />
      </Box>
    </Stack>
  );
};

export default PartnerProductsTable;
