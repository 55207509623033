// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import {
  PartnerBusinessStatusEnum,
  PartnerBusinessTypeEnum,
} from 'enums/accountTypeEnum';
import { updatePartnerNameHelper } from 'helpers/myPartnersStoreHelpers';
import { EMPTY_ROLE, IRole } from 'models/role';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

export enum PartnerTypeEnum {
  UNKNOWN = 'unknown',
  EXTERNAL = 'external',
  INTERNAL = 'internal',
}

export interface IPartnerEntry {
  id: string;
  name: string;
  type: PartnerTypeEnum;
  role: IRole;
  secursusEnabled: boolean;
  createdAt: number;
  businessInformation: {
    status: PartnerBusinessStatusEnum;
    type: PartnerBusinessTypeEnum;
  };
  remoteControlEnabled: boolean;
  fwUpdateEnabled: boolean;
  smsEnabled: boolean;
  carriers: string[];
}

export interface IActivePartnerStore {
  activePartner: IPartnerEntry;
  partners: IPartnerEntry[];
  setPartners: (partners: IPartnerEntry[] | []) => void;
  resetActivePartner: () => void;
  setActivePartner: (partner: IPartnerEntry) => void;
  updatePartnerName: (input: { partnerId: string; name: string }) => void;
}

const INITIAL_STATE = {
  activePartner: {
    id: '',
    name: '',
    type: PartnerTypeEnum.UNKNOWN,
    role: EMPTY_ROLE,
    secursusEnabled: false,
    email: '',
    createdAt: 0,
    businessInformation: {
      status: PartnerBusinessStatusEnum.UNSPECIFIED,
      type: PartnerBusinessTypeEnum.UNSPECIFIED,
    },
    remoteControlEnabled: false,
    fwUpdateEnabled: false,
    smsEnabled: false,
    carriers: [],
    isPartnerIDCardCompleted: false,
  },
  partners: [],
};

const useMyPartnersStore = create<IActivePartnerStore>()(
  devtools(
    persist(
      set => ({
        ...INITIAL_STATE,
        setPartners: partners => set(state => ({ partners })),
        setActivePartner: activePartner => set(state => ({ activePartner })),
        resetActivePartner: () =>
          set(state => ({ activePartner: INITIAL_STATE.activePartner })),
        updatePartnerName: ({ partnerId, name }) =>
          set(state => updatePartnerNameHelper({ partnerId, state, name })),
      }),
      {
        name: 'partners',
      }
    )
  )
);

export const partnersSelector = (state: IActivePartnerStore) => state.partners;
export const activePartnerSelector = (state: IActivePartnerStore) =>
  state.activePartner;
export const setPartnersSelector = (state: IActivePartnerStore) =>
  state.setPartners;
export const resetActivePartnerSelector = (state: IActivePartnerStore) =>
  state.resetActivePartner;
export const setActivePartnerSelector = (state: IActivePartnerStore) =>
  state.setActivePartner;
export const updatePartnerNameSelector = (state: IActivePartnerStore) =>
  state.updatePartnerName;

export default useMyPartnersStore;
