// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useCallback } from 'react';

import { Text, Theme } from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';

interface IBatteryInfoIcon {
  batteryLevel: number | undefined;
  variant?: keyof Theme['fontStyles'] | undefined;
}

const BatteryInfoText = ({
  batteryLevel,
  variant = 'bodyTextS',
}: IBatteryInfoIcon) => {
  const { t } = useTranslation('products');

  const getBatteryLevelText = useCallback(
    (batteryLevel: number | undefined) => {
      if (!batteryLevel) {
        return;
      } else {
        const batteryLevelText = t('common.batteryLevel', {
          batteryLevel: batteryLevel,
        });

        if (batteryLevel <= 33) {
          return (
            <Text variant={variant} color="custom.error.red.100">
              {batteryLevelText}
            </Text>
          );
        } else if (batteryLevel <= 66) {
          return (
            <Text variant={variant} color="custom.neutral.black.100">
              {batteryLevelText}
            </Text>
          );
        } else if (batteryLevel > 66) {
          return (
            <Text variant={variant} color="custom.primary.100">
              {batteryLevelText}
            </Text>
          );
        }
      }
    },
    [t, variant]
  );

  return <>{getBatteryLevelText(batteryLevel)}</>;
};

export default BatteryInfoText;
