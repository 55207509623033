// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useContext, useEffect, useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Grid,
  NewTextField as TextField,
  Text,
  NewTooltip as Tooltip,
} from '@livingpackets/design-system-react-next';
import { PhoneNumber } from 'components/form/phoneNumber';
import WarnBeforeQuit from 'components/form/WarnBeforeQuit';
import useToastMessages from 'hooks/useToastMessages';
import { isEmpty } from 'lodash/fp';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { accountPartnershipInformation } from 'schemas/accountPartnershipInformation';

import {
  useUpdatePartnership,
  FormValues,
} from '../../../api/partnership/updatePartnership';
import { WbqContext } from '../../../routes/Partnership';
import {
  PartnerBusinessStatusEnum,
  Partnership,
} from '../../../types/partnership';
import { ExpandableSubmitButton } from '../../commons/ExpandableSubmitButton';

interface Props {
  partnership: Partnership;
}

const IdentificationForm = ({ partnership }: Props) => {
  const { t } = useTranslation(['forms', 'account', 'messages']);
  const { success: toastSuccess } = useToastMessages();

  const isFormDisabled =
    partnership.business_information.status ===
    PartnerBusinessStatusEnum.BLOCKED;

  const initialValues = useMemo(
    () => ({
      partner_id_card: {
        country_code: partnership?.partner_id_card.country_code,
        vat_number: partnership?.partner_id_card.vat_number,
        company_id: partnership?.partner_id_card.company_id,
        email: partnership?.partner_id_card.email,
        website: partnership?.partner_id_card.website,
      },
      phone_number: partnership?.phone_number,
    }),
    [partnership]
  );

  const { mutate: updatePartnership, isPending: updateInProgress } =
    useUpdatePartnership({ initialValues });

  const {
    handleSubmit,
    reset,
    control,
    formState: { isDirty, errors },
    watch,
  } = useForm<FormValues>({
    resolver: yupResolver(accountPartnershipInformation()),
    defaultValues: initialValues,
    mode: 'onTouched',
  });

  const onSubmit = handleSubmit((values: FormValues) => {
    // 'mutate' (here renamed 'updatePartnership') from useQuery will handle Error (dont throw error here but catch it in useQuery)
    // Therefor, here, 'updatePartnership' do not return a Promise
    // That's why handleSubmit is not async and isSubmitting (from useForm) is not working properly.
    // We need to use 'isPending' from useQuery instead (aka updateInProgress here)

    updatePartnership(
      { values, partnershipId: partnership.id },
      {
        onSuccess: data => {
          const isPartnerCompleted =
            !partnership.partner_id_card.is_partner_id_card_completed &&
            data.partner_id_card.is_partner_id_card_completed;

          if (
            partnership.partner_id_card.country_code === '' &&
            partnership.partner_id_card.country_code !==
              data.partner_id_card.country_code &&
            !isPartnerCompleted
          ) {
            toastSuccess({
              title: t('messages:partnerAccountMandatoryFieldSuccess.title'),
              msg: t('messages:partnerAccountMandatoryFieldSuccess.message'),
            });
          } else if (isPartnerCompleted) {
            toastSuccess({
              title: t('messages:partnerCompletedAccountSuccess.title'),
              msg: t('messages:partnerCompletedAccountSuccess.message'),
            });
          } else {
            // generic toast message success
            toastSuccess('messages:updateProfileSuccess.message');
          }
        },
      }
    );
  });

  // WarnBeforeQuit queue
  const { queue, setQueue } = useContext(WbqContext);

  // Add partneship form to the list off forms to check before leaving the page
  useEffect(() => {
    setQueue((queue: any) => ({
      ...queue,
      partnership: {
        isDirty,
        onSubmit,
        reset,
      },
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  // Reinitialize form after partneship changes (ie: form submitted, useQuery update)
  useEffect(() => reset(initialValues), [reset, initialValues]);

  const countryCode = watch('partner_id_card.country_code');

  return (
    <Grid container spacing="0.875rem">
      <WarnBeforeQuit queue={queue} />
      {/* PARTNER ID */}
      <Grid item mobile={12} tablet={3}>
        <Tooltip
          placement="bottom-start"
          enterDelay={500}
          title={
            <Text variant="bodyTextL">
              {t('account:partnership.information.partnerIdTooltip')}
            </Text>
          }
          sx={{ maxWidth: '15.125rem' }}
        >
          <TextField
            disabled
            label={t('forms:partnerId.label')}
            defaultValue={partnership.partner_id_card.client_id}
          />
        </Tooltip>
      </Grid>
      <Grid item mobile={12} tablet={4.5}>
        <Tooltip
          placement="bottom-start"
          enterDelay={500}
          title={
            <Text variant="bodyTextL">
              {t('account:partnership.information.contractIdTooltip')}
            </Text>
          }
          sx={{ maxWidth: '15.125rem' }}
        >
          <TextField
            disabled
            label={t('forms:partnershipContractId.label')}
            defaultValue={partnership.contract_id}
          />
        </Tooltip>
      </Grid>
      {/* PARTNERSHIP NAME  */}
      <Grid item mobile={12} tablet={4.5}>
        <TextField
          required
          disabled
          label={t('forms:partnershipName.label')}
          defaultValue={partnership.partner_id_card.client_name}
        />
      </Grid>
      {/* COUNTRY_CODE */}
      <Grid item mobile={12} tablet={2}>
        <Controller
          name="partner_id_card.country_code"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              disabled={isFormDisabled}
              required
              label={t('forms:countryCode.label')}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              {...field}
            />
          )}
        />
      </Grid>
      {/*  VAT_NUMBER */}
      <Grid item mobile={12} tablet={5}>
        <Controller
          name="partner_id_card.vat_number"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              disabled={isFormDisabled}
              label={t('forms:vatNumber.label')}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              {...field}
            />
          )}
        />
      </Grid>
      {/*  COMPANY ID (SIREN...) */}
      <Grid item mobile={12} tablet={5}>
        <Controller
          name="partner_id_card.company_id"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              disabled={isFormDisabled}
              required={countryCode === 'FR'}
              label={t('forms:companyCode.label')}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item mobile={12} tablet={12}>
        <Text
          variant="titleS"
          sx={{ marginTop: '1.5rem', marginBottom: '0.75rem' }}
        >
          {t('account:partnership.information.contact')}
        </Text>
      </Grid>
      {/* PHONE NUMBER  */}
      <Grid item mobile={12} tablet={6}>
        <Controller
          name="phone_number"
          control={control}
          render={({ field, fieldState: { error, invalid } }) => (
            <PhoneNumber
              disabled={isFormDisabled}
              label={t('forms:phoneNumber.label')}
              defaultCountryCode="FR"
              error={invalid}
              helperText={error?.message}
              required
              {...field}
            />
          )}
        />
      </Grid>
      {/* EMAIL */}
      <Grid item mobile={12} tablet={6}>
        <Controller
          name="partner_id_card.email"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              disabled={isFormDisabled}
              label={t('forms:email.label')}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              {...field}
            />
          )}
        />
      </Grid>
      {/* WEBSITE */}
      <Grid item mobile={12}>
        <Controller
          name="partner_id_card.website"
          control={control}
          render={({ field }) => (
            <TextField
              disabled={isFormDisabled}
              label={t('forms:website.label')}
              {...field}
            />
          )}
        />
      </Grid>
      <ExpandableSubmitButton
        prefix="partnership"
        isDirty={isDirty}
        isValid={isEmpty(errors)}
        isSubmitting={updateInProgress}
        onSubmit={onSubmit}
        onCancel={() => reset(initialValues)}
      />
    </Grid>
  );
};
export default IdentificationForm;
