// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  ArrowLeftIcon,
  ButtonV2 as Button,
  Stack,
  Text,
} from '@livingpackets/design-system-react-next';

import { ProductEntity } from '../../features/products/entities/ProductEntity';

type Props = {
  product?: ProductEntity;
  callback: () => void;
  path: string;
  subtitle: string;
  title: string | React.ReactNode;
};

const Header = ({ callback, path, subtitle, title }: Props) => (
  <Stack gap="1rem">
    <Stack direction="row" alignItems="center" spacing=".75rem">
      <Button
        data-testid="header-back-button"
        variant="tertiary"
        icon={ArrowLeftIcon}
        onClick={callback}
      />
      <Text variant="bodyTextXS" color="custom.neutral.black.50">
        {path}
      </Text>
    </Stack>
    <Stack ml=".25rem" mb={3} gap="0.5rem">
      <Text variant="titleXS" color="custom.neutral.white.pure">
        {subtitle}
      </Text>
      <Text variant="titleXL" color="custom.neutral.white.pure">
        {title}
      </Text>
    </Stack>
  </Stack>
);

export default Header;
