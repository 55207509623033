// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import boxShowcaseClosedFoldImg from 'assets/img/products/showcase/box/box-showcase-closed-fold.png';
import boxShowcaseClosedFoldImg2x from 'assets/img/products/showcase/box/box-showcase-closed-fold@2x.png';
import boxShowcaseClosedFoldImg3x from 'assets/img/products/showcase/box/box-showcase-closed-fold@3x.png';
import boxShowcaseOpenImg from 'assets/img/products/showcase/box/box-showcase-open.png';
import boxShowcaseOpenImg2x from 'assets/img/products/showcase/box/box-showcase-open@2x.png';
import boxShowcaseOpenImg3x from 'assets/img/products/showcase/box/box-showcase-open@3x.png';
import boxShowcaseImg from 'assets/img/products/showcase/box/box-showcase.png';
import boxShowcaseImg2x from 'assets/img/products/showcase/box/box-showcase@2x.png';
import boxShowcaseImg3x from 'assets/img/products/showcase/box/box-showcase@3x.png';
import boxProShowcaseImg from 'assets/img/products/showcase/boxPro/box-pro-showcase.png';
import boxProShowcaseImg2x from 'assets/img/products/showcase/boxPro/box-pro-showcase@2x.png';
import boxProShowcaseImg3x from 'assets/img/products/showcase/boxPro/box-pro-showcase@3x.png';
import tabletShowcaseImg from 'assets/img/products/showcase/tablet/tablet-showcase.png';
import tabletShowcaseImg2x from 'assets/img/products/showcase/tablet/tablet-showcase@2x.png';
import tabletShowcaseImg3x from 'assets/img/products/showcase/tablet/tablet-showcase@3x.png';
import { normalizeKey } from 'helpers/i18n';
import { SentryCaptureException } from 'helpers/tracking';
import { ShipmentContractStateEnum } from 'models/shipment';

import { ProductAPI } from '../api/models/productAPI';
import {
  articleCodeBoxList,
  articleCodeBoxProList,
  articleCodeTabletList,
  ProductArticleCodeEnum,
  ProductClosingStateEnum,
  ProductTypeEnum,
} from '../types';

export class ProductEntity {
  static create(data: ProductAPI): ProductEntity {
    try {
      // Validate data to ensure it matches the ProductAPI type
      if (!data || typeof data !== 'object') {
        throw new Error('Invalid data: data must be an object');
      }

      return new ProductEntity(data);
    } catch (error: any) {
      SentryCaptureException(error, null);

      throw new Error(`Failed to create ProductEntity: ${error.message}`);
    }
  }

  alias: string;
  articleCode: ProductArticleCodeEnum;
  articleDescription: string;
  batteryLevel: number;
  closingState: ProductClosingStateEnum;
  contractState: ShipmentContractStateEnum;
  contractStateUpdatedAt: number;
  currentScreen: string;
  distanceTravelled: number;
  firmwareVersion: string;
  holderId: string;
  holderName: string;
  holderType: string;
  humidityExternal: number;
  humidityInternal: number;
  lastShipmentId: string;
  location: {
    latitude: number;
    longitude: number;
  };
  locationUpdatedAt: number;
  lockState: boolean;
  lpUi: string;
  partnerId: string;
  partnerSince: number;
  pressure: number;
  productId: string;
  shipmentsOperated: number;
  shopId: string;
  temperatureExternal: number;
  temperatureInternal: number;
  updatedAt: string;

  constructor(data: ProductAPI) {
    this.alias = data.alias;
    this.articleCode = data.article_code;
    this.articleDescription = data.article_description;
    this.batteryLevel = data.battery_level;
    this.closingState = data.closing_state;
    this.contractState = data.contract_state;
    this.contractStateUpdatedAt = data.contract_state_updated_at;
    this.currentScreen = data.current_screen;
    this.distanceTravelled = data.distance_travelled;
    this.firmwareVersion = data.firmware_version;
    this.holderId = data.holder_id;
    this.holderName = data.holder_name;
    this.holderType = data.holder_type;
    this.humidityExternal = data.humidity_external;
    this.humidityInternal = data.humidity_internal;
    this.lastShipmentId = data.last_shipment_id;
    this.location = data.location;
    this.locationUpdatedAt = data.location_updated_at;
    this.lockState = data.lock_state;
    this.lpUi = data.lp_ui;
    this.partnerId = data.partner_id;
    this.partnerSince = data.partner_since;
    this.pressure = data.pressure;
    this.productId = data.product_id;
    this.shipmentsOperated = data.shipments_operated;
    this.shopId = data.shop_id;
    this.temperatureExternal = data.temperature_external;
    this.temperatureInternal = data.temperature_internal;
    this.updatedAt = data.updated_at as any;
  }

  /**
   * Determines the product type based on the article code.
   *
   * @returns {ProductTypeEnum} The type of the product.
   */
  public get productType(): ProductTypeEnum {
    if (!this.articleCode) {
      return ProductTypeEnum.UNKNOWN;
    }

    if (articleCodeBoxList.includes(this.articleCode)) {
      return ProductTypeEnum.BOX;
    }

    if (articleCodeBoxProList.includes(this.articleCode)) {
      return ProductTypeEnum.BOX_PRO;
    }

    if (articleCodeTabletList.includes(this.articleCode)) {
      return ProductTypeEnum.TABLET;
    }

    return ProductTypeEnum.UNKNOWN;
  }

  public get productTitle(): TemplateStringsArray {
    switch (this.productType) {
      case ProductTypeEnum.BOX:
        return normalizeKey('detail.showcaseTitle.box');
      case ProductTypeEnum.BOX_PRO:
        return normalizeKey('detail.showcaseTitle.boxPro');
      case ProductTypeEnum.TABLET:
        return normalizeKey('detail.showcaseTitle.tablet');
    }

    throw new Error('Unknown product type');
  }

  public get showcaseImgSources(): {
    src: string;
    src2x: string;
    src3x: string;
  } {
    let showcaseImg = undefined;
    let showcaseImg2x = undefined;
    let showcaseImg3x = undefined;

    if (this.productType === ProductTypeEnum.BOX) {
      switch (this.closingState) {
        case ProductClosingStateEnum.CONFIG_OPEN:
          showcaseImg = boxShowcaseOpenImg;
          showcaseImg2x = boxShowcaseOpenImg2x;
          showcaseImg3x = boxShowcaseOpenImg3x;
          break;
        case ProductClosingStateEnum.CONFIG_32L:
          showcaseImg = boxShowcaseImg;
          showcaseImg2x = boxShowcaseImg2x;
          showcaseImg3x = boxShowcaseImg3x;
          break;
        case ProductClosingStateEnum.CONFIG_2L:
          showcaseImg = boxShowcaseClosedFoldImg;
          showcaseImg2x = boxShowcaseClosedFoldImg2x;
          showcaseImg3x = boxShowcaseClosedFoldImg3x;
          break;
        case ProductClosingStateEnum.CONFIG_CLOSED_FOLD:
          showcaseImg = boxShowcaseClosedFoldImg;
          showcaseImg2x = boxShowcaseClosedFoldImg2x;
          showcaseImg3x = boxShowcaseClosedFoldImg3x;
          break;
        default:
          showcaseImg = boxShowcaseImg;
          showcaseImg2x = boxShowcaseImg2x;
          showcaseImg3x = boxShowcaseImg3x;
      }
    }

    if (this.productType === ProductTypeEnum.BOX_PRO) {
      showcaseImg = boxProShowcaseImg;
      showcaseImg2x = boxProShowcaseImg2x;
      showcaseImg3x = boxProShowcaseImg3x;
    }

    if (this.productType === ProductTypeEnum.TABLET) {
      showcaseImg = tabletShowcaseImg;
      showcaseImg2x = tabletShowcaseImg2x;
      showcaseImg3x = tabletShowcaseImg3x;
    }

    if (showcaseImg && showcaseImg2x && showcaseImg3x) {
      return {
        src: showcaseImg,
        src2x: showcaseImg2x,
        src3x: showcaseImg3x,
      };
    }

    return {
      src: '',
      src2x: '',
      src3x: '',
    };
  }
}
